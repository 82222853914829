import axios from 'axios';


export const apiFullURL = process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://api.dermadetect.com';

export const axiosInstance = axios.create({
  baseURL: apiFullURL,
  timeout: 10000,
  withCredentials: true,
  headers: {
      'CSRF-Token': '',
      'Content-Type': 'application/json',
    },
  xsrfHeaderName: 'CSRF-Token',
});

axiosInstance.interceptors.request.use(
  async function(config) {
    const access_token = localStorage.getItem('access_token');
    if (access_token !== null) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(response => {
  return response;
}, err => {
 return Promise.reject(err);
});