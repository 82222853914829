import * as moment from 'moment';
import {calcAgeFromDate} from "./helper.functions";

const DATE_TIME_FORMATE = 'HH:mm:ss DD-MM-YY';
const pfx = 'patientsDetails.';

export const caseViewTitleGenerator = (pd, t, language) => {
  if (!pd || !pd.patientDetailsTranslated) return '';
  let data = pd.patientDetailsTranslated;
  const { case_id, user_identifier, created_at } = pd
  let id = user_identifier; 
  const patientGenderAge = calcAgeFromDate(data.age.date, data.gender.en, t)

  const created = `${t('patientsList.createdAt')}: ${moment(created_at).format(DATE_TIME_FORMATE)}`;
  const title = t(`${pfx}title`);
  const caseIdText = t(`${pfx}caseId`);
  const patient_id = pd.patient_id ? `${pd.patient_id}, ` : '';
  const patient_email = pd.patient_email ? `${pd.patient_email}, ` : '';
  
  return `${caseIdText}${case_id}, ${(id != null && id !== 'null') ? `${title} # ${id}, ` : ''}${patient_id}${patient_email}${patientGenderAge}, ${created}`
}

export const generateAnnotations = (annotations, t) => {
  const arr = []
  for (let annotation of annotations) {
    if (!annotation || annotation.is_canceled || !Array.isArray(annotation.diagnosis)) { continue; }
    let annotationString = '';
    annotation.diagnosis.filter(diag => !!diag.text)
      .map(diagnose => {
        annotationString += `${diagnose.text}`;
        if (diagnose.characteristic1) {
          annotationString += `, ${diagnose.characteristic1}`;
        }
        if (diagnose.characteristic2) {
          annotationString += `, ${diagnose.characteristic2}`;
        }
        if (diagnose.comment) {
          annotationString += `, ${diagnose.comment}`;
        }
        annotationString += '\n';
        return annotationString;
      })
    if (!!annotation.free_text && annotation.free_text.text) {
      annotationString += `${t('anamnesis.diseaseRemark')}:\n`;
      annotationString += `${annotation.free_text.text}`
    }
    arr.push(annotationString)
  }
  return arr.filter(item => !!item).join('\n')
}

export function cleanBlankAttributes(obj) {
  let copyObj = { ...obj };
  for (var propName in copyObj) {
    if (copyObj.hasOwnProperty(propName) && (typeof copyObj[propName] === 'object')) {
      const newObj = cleanBlankAttributes(copyObj[propName]);
      copyObj[propName] = { ...copyObj[propName], ...newObj };
    } else if (!copyObj[propName] || copyObj[propName] === null || copyObj[propName] === undefined) {
      copyObj[propName] = 0;
    }
  }
  return copyObj;
}

/**
 *
  // ANAMNESIA CHANGE
  // TITLE
  // CASE ID, PATEINT ID, GENDER, AGE, CREATED AT
  // BACKGROUND:
  // CURRENT DISEASE:
  // LOCATION
  // ANAMNESIS - if there is no itching, mention this here.
  // ... || PAIN & SPREAD
  // FREE TEXT NOTES FROM PATIENT
  // DIAGNOSIS
  // FREE TEXT FROM THE PHYSCIAN - if exists;
 */
const scipInCurrentDisease = {
  'quantity': true,
  'widespreadPalmFeet': true,
  'widespreadFace': true,
  'swelling': true,
  'lossOfHair': true,
  'itch': true,
  'pain': true,
}

export const copyToClipboard = (pd, annotations, t, lng, permissions = {}, returnText = false) => {
  return (data) => {
    let text = `${t('anamnesis.copyToClipboardStartText')}\n`;
    const tempPregnant = (s) => `*** ${s} ***`;

    try {
      data = pd.patientDetailsTranslated

      text += caseViewTitleGenerator(pd, t, lng, true) + '\n';
      const diseaseInfoCopy = data.Disease_Information;
      const diseaseInfo = cleanBlankAttributes(diseaseInfoCopy);
      const pregnancyKey = 'pregnancy';
      const isPregnantObj = diseaseInfo && diseaseInfo[pregnancyKey];
      const isPregnant = (
        isPregnantObj && isPregnantObj[lng] &&
        (WORDS_DESCRIBING_PREGNANCY.findIndex(w => isPregnantObj[lng].includes(w)) !== -1)
      );

      if (permissions['isBackground']) {
        if (isPregnant && !!isPregnantObj && !!diseaseInfo) {
          const chars = Object.keys(diseaseInfo)
            .filter(k => !!k && diseaseInfo[k] && !diseaseInfo[pregnancyKey] && !!diseaseInfo[k][lng])
            .map(k => {
              return diseaseInfo[k][lng];
            }).join('\n');
          if (chars && chars.trim() !== '' && chars.trim() !== ' ') {
            text += `${t('anamnesis.diseaseInformation')}: `;
            text += `${tempPregnant(isPregnantObj[lng])}\n`;
            text += chars;
            text += '\n';
          }
        } else if (!!diseaseInfo) {
          const chars = Object.values(diseaseInfo)
            .filter(item => !!item[lng])
            .map(item => item[lng])
            .join('\n');
          if (chars && chars.trim() !== '' && chars.trim() !== ' ') {
            text += `${t('anamnesis.diseaseInformation')}: `;
            text += chars;
            text += '\n';
          }
        }
      }

      let sectionAfterLocation = '';
      const characters = data['Characteristics'];
      const section = cleanBlankAttributes(characters);
      // if (permissions['isCurrentDiagnose']) {
      if (section) {
        let characteristics = '';
        let chars = '';
        let chars2 = '';
        Object.keys(section)
          .filter(key => !!section[key][lng] && key !== 'locationsWithoutImages')
          .forEach(key => {
            if (section[key][lng] && !scipInCurrentDisease[key]) {
              if (chars) {
                chars += `, ${section[key][lng]}`;
              } else {
                chars += section[key][lng];
              }
            } else if (section[key][lng] && !!scipInCurrentDisease[key]) {
              if (chars2) {
                chars2 += `, ${section[key][lng]}`;
              } else {
                chars2 += section[key][lng];
              }
            }
          });
        chars = chars.trim();
        chars2 = chars2.trim();
        if (chars && chars !== '' && chars !== ' ') {
          text += `${t('anamnesis.characteristics')}: `
          if (isPregnant && !!isPregnantObj) {
            characteristics += `${tempPregnant(isPregnantObj[lng])}\n`;
          }
          text += chars;
          text += characteristics + '\n';
        }
        if (chars2 && chars2 !== '' && chars !== ' ') {
          sectionAfterLocation += chars2 + '\n';
        }
      }
      // }

      text += t('anamnesis.locationTitle');
      if(typeof lng == 'object' || Array.isArray(lng)) {
        lng = lng.lang;
      }
      const locWithImages = !!data.LocationsWithImages ? data.LocationsWithImages[lng].join(', ') : '';

      const locWithoutImages = (!!section && section['locationsWithoutImages']) ?
        section['locationsWithoutImages'][lng] : '';
      text += [locWithoutImages, locWithImages].filter(item => !!item).join(', ')
      text += '\n';
      if (sectionAfterLocation) {
        text += sectionAfterLocation;
      }
      const negativeAnswersObjCopy = data.negativeAnswers;
      const negativeAnswersObj = cleanBlankAttributes(negativeAnswersObjCopy);
      if (permissions['isNegative']) {
        let chars = '';
        for (const negative in negativeAnswersObj) {
          const negativeAnswer = (negativeAnswersObj[negative] && negativeAnswersObj[negative][lng]) ?
            negativeAnswersObj[negative][lng] : null;
          if (negativeAnswer) {
            chars += `${negativeAnswersObj[negative][lng]},`;
          }
        }
        if (chars && chars.trim() !== '' && chars.trim() !== ' ') {
          text += `${t('anamnesis.negativeAnswerCopyToClipBoard')}: `;
          text += chars;
          text += '\n';
        }
      }
      // locations
      const diagnosis = generateAnnotations(annotations, t);
      if (diagnosis && diagnosis.trim() !== '' && diagnosis.trim() !== ' ') {
        text += `${t('anamnesis.diseaseItem')}:\n`;
        text += diagnosis;
      }

    } catch (e) {
      console.log(e)
    }

    if(returnText) {
      return text;
    }

    let textField = document.createElement('textarea')
    textField.innerHTML = text
    document.body.appendChild(textField)
    textField.select()
    let result = document.execCommand('copy')
    if(!result) {
      try {
        window.navigator.clipboard.writeText(text).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      } catch(e) {

      }
    }
    textField.remove()
  }
}


export const DIAGNOSE_DELETE_PROCESS = Object.freeze({
  CLEAN_FIRST: 'CLEAN_FIRST',
  CLEAN_SECOND: 'CLEAN_SECOND',
  CLEAN_THIRD: 'CLEAN_THIRD',
});

export const WORDS_DESCRIBING_PREGNANCY = ['בהריון', 'Pregnant'];
export const WORDS_DESCRIBING_NO_PREGNANCY = ['אין הריון', 'not pregnant'];
export const PREGNANCY_KEY = 'pregnancy';
export const ISSUES_KEY = 'otherDiseases';
export const COUGH_KEY = 'cough';


export const copyIdToClipboard = (userId) => {
  if(userId) {
    let textField = document.createElement('textarea')
    let elemID = userId;
    // elemID = `${elemID}`.replace(/^./, '') // replacing first char
    elemID = elemID.replace(/-/g, ''); // replacing dash
    const textToAdd = elemID;
    textField.innerHTML = textToAdd;
    document.body.appendChild(textField)
    textField.select()
    let result = document.execCommand('copy')
    if(!result) {
      try {
        window.navigator.clipboard.writeText(textToAdd).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      } catch(e) {

      }
    }
    textField.remove()
  }
}
