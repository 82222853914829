import {take, put} from 'redux-saga/effects'
import { CASE_EDIT_PAGE_BLOCK_STATUS, initCasesBatch, updateImageRequirementBlock } from '../actions/cases.batch.actions';
import { SETTINGS_ACTION_MAP, SETTINGS_LOADING_ACTIONS } from '../actions/settings.actions';
import { SYSTEM_STATUSES } from '../components/helpers/groups';
import { ROUTES } from '../helpers/route.helper';
import { generateUrlParams } from '../sagas/helper';

export const VIEW_NEXT_AVAILABLE_CASE_OPTIONS = Object.freeze({
  fillFromBegining: 'INIT_SCOPE',
  fillFromBeginingAndRedirect: 'INIT_SCOPE_AND_REDIRECT',
  fillFromBeginingAndRedirectToFirstPosition: 'INIT_SCOPE_AND_REDIRECT_TO_FIRST_POSITION',
  updateRelevant: 'UPDATE_SCOPE',
  updateRelevantAndredirectToFirstPosition: 'UPDATE_SCOPE_REDIRECT_TO_FIRST',
  updateRelevantAndRedirect: 'UPDATE_SCOPE_REDIRECT',
});

export function* physicianFlow() {

  while (true) {
    const action = yield take('VIEW_NEXT_AVAILABLE_CASE');
    let {
      history,    // @param: object - to redirect via react-dom library - optional
      option,     // @param: VIEW_NEXT_AVAILABLE_CASE_OPTIONS
      redirectTo, // @param: string -optional
      caseId,     // @param: uuid -optional
      cases
      // message,    // @param: boolean - optional
    } = action.payload;
    // const { settings: { user }} = yield select();
    // yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOn, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE}});
    // const cases = yield call(rest.nextAvailableCase, user);
    // const cases = yield select(getAwaitingCasesList);
    // yield put(resetCaseData())
    if (!Array.isArray(cases) || !cases.length) {
      yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE} });
      yield put({ type: 'SET_FAILED_AVAILABLE_CASES', payload: true });
      history.push(generateUrlParams(ROUTES.PATIENTS_LIST));
      continue;
    } else {
      yield put(updateImageRequirementBlock(CASE_EDIT_PAGE_BLOCK_STATUS.SET_IMAGE_CHECK_BLOCK));
      switch(option) {
        case VIEW_NEXT_AVAILABLE_CASE_OPTIONS.fillFromBegining: {
          const payload = cases
            .map(c => ({ 
              uuid: c.uuid, 
              status: c.status, 
              caseId: c.id ? c.id : c.case_id, 
              batch_id: c.batch_id
            }));
          yield put(initCasesBatch(payload));
          yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload });
          yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE} });
          continue;
        };
        case VIEW_NEXT_AVAILABLE_CASE_OPTIONS.fillFromBeginingAndRedirectToFirstPosition: {
          // 
          const array = cases
            .map(c => ({ 
              uuid: c.uuid, 
              status: c.status, 
              caseId: c.id ? c.id : c.case_id, 
              batch_id: c.batch_id 
            }));
          let index = array.findIndex((c) => c.status === SYSTEM_STATUSES.IN_PROCESS || c.status === SYSTEM_STATUSES.EDITING )
          if (index === -1) {
            index = 0; 
          }
          yield put(initCasesBatch(array));
          
          // openCefAction.response(array[index].uuid, array[index].batch_id, array[index].status)
          
          yield put({ type: 'UPDATE_AWAITING_CASES_ARRAY', payload: { array, index } });
          
          yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE} });
          history.push(generateUrlParams(`${redirectTo}/${cases[index].uuid}`));
          continue;
        };
        case VIEW_NEXT_AVAILABLE_CASE_OPTIONS.fillFromBeginingAndRedirect: {
          const payload = cases
            .map(c => ({ uuid: c.uuid, status: c.status, caseId: c.id ? c.id : c.case_id, batch_id: c.batch_id }));
          yield put(initCasesBatch(payload));
          yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload });
          yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE} });
          history.push(generateUrlParams(redirectTo));
          continue;
        };
        case VIEW_NEXT_AVAILABLE_CASE_OPTIONS.updateRelevant: {
          const array = cases
            .map(c => ({ uuid: c.uuid, status: c.status, caseId: c.id ? c.id : c.case_id, batch_id: c.batch_id }));
          yield put(initCasesBatch(array));
          let index = 0
          if (caseId) {
            index = cases.findIndex(c => c.uuid === caseId);
          }
          yield put({ type: 'UPDATE_AWAITING_CASES_ARRAY', payload: { array, index } });
          yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE} });
          continue;
        };
        case VIEW_NEXT_AVAILABLE_CASE_OPTIONS.updateRelevantAndRedirect: {
          const array = cases
            .map(c => ({ uuid: c.uuid, status: c.status, caseId: c.id ? c.id : c.case_id, batch_id: c.batch_id }));
          yield put({ type: 'UPDATE_AWAITING_CASES_ARRAY', payload: { array, index: 0} });
          history.push(generateUrlParams(redirectTo));
          yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE}});
          continue;
        }
        case VIEW_NEXT_AVAILABLE_CASE_OPTIONS.updateRelevantAndredirectToFirstPosition: {
          const array = cases
            .map(c => ({ uuid: c.uuid, status: c.status, caseId: c.id ? c.id : c.case_id, batch_id: c.batch_id }));
          yield put({ type: 'UPDATE_AWAITING_CASES_ARRAY', payload: { array, index: 0} });
          yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE} });
          history.push(generateUrlParams(`${redirectTo}/${cases[0].uuid}`));
          continue;
        }
        default:
          yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.NEXT_CASE} });
          continue;
      }
    }
  }


}
