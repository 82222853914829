
export const PATIENT_DETAILS_ACTIONS = Object.freeze({
    copyPatientId: 'COPY_PATIENT_ID',
    CREATE_TREATMENT_PLAN_START: 'CREATE_TREATMENT_PLAN_START',
    CREATE_TREATMENT_PLAN_SUCCESS: 'CREATE_TREATMENT_PLAN_SUCCESS',
    CREATE_TREATMENT_PLAN_FAIL: 'CREATE_TREATMENT_PLAN_FAIL',
});

export const createTreatmentPlanStart = (payload) => {
  return {
    type: PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_START,
    payload
  }
}
export const createTreatmentPlanSuccess = (payload) => {
  return {
    type: PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_SUCCESS,
    payload
  }
}
export const createTreatmentPlanFail = (payload) => {
  return {
    type: PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_FAIL,
    payload
  }
}
