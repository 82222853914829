import { PATIENT_LIST_ACTIONS_MAP } from '../actions/patient.list.actions';
import { BABY_AGE_OPTIONS, TIME_OPTIONS, LIST_ORDER } from '../components/PatientList/helper/filters';

export const DEFAUL_LIMIT = 100;
export const DEFAUL_OFFSET = 0;
export const DEFAULT_STATUSES =  ["filterForAll", "status1", "status2", "status3", "status33", "status4", 'status9'];

export const initPatientListState = {
    patients: [],
    subscribePatients: false,
    totalCount: 0,
    patientsLoading: false,
    filterPending: false,
    order: LIST_ORDER.desc,
    orderCreatedAt: '',
    limit: DEFAUL_LIMIT,
    offset: DEFAUL_OFFSET,
    page: 0,
    statuses: DEFAULT_STATUSES,
    error: null,
    modifiedAt: new Date(),
    globalFilterString: '',
    valueCaseId: '',
    valueCreatedTime: TIME_OPTIONS.all,
    valueDiagnosedAt: TIME_OPTIONS.all,
    valueDiagnosedBy: TIME_OPTIONS.all,
    valuePatientId: '',
    age: BABY_AGE_OPTIONS.ageAll,
    dateRange: null,
    orderBy: 'asc'
}

const patientListReducer = (state = initPatientListState, action) => {
    switch (action.type) {
        case PATIENT_LIST_ACTIONS_MAP.subscrbeGetPatients:
            return {
                ...state,
                subscribePatients: true,
            }

        case PATIENT_LIST_ACTIONS_MAP.updateListOrderBy:
            return {
                ...state,
                limit: action.payload.orderBy,
            }

        case PATIENT_LIST_ACTIONS_MAP.unsubscrbeGetPatients:
            return {
                ...state,
                subscribePatients: false,
            }
        case PATIENT_LIST_ACTIONS_MAP.initFilterSettingsStart:
            return {
                ...state,
                filterPending: false,
                limit: action.payload.filters.limit,
                offset: action.payload.filters.offset,
                globalFilterString: action.payload.filters.globalFilterString,
                valueCaseId: action.payload.filters.valueCaseId,
                valueCreatedTime: action.payload.filters.valueCreatedTime,
                valueDiagnosedAt: action.payload.filters.valueDiagnosedAt,
                valueDiagnosedBy: action.payload.filters.valueDiagnosedBy,
                valuePatientId: action.payload.filters.valuePatientId,
                age: action.payload.filters.age,
                dateRange: action.payload.filters.dateRange,
                orderCreatedAt: action.payload.filters.orderCreatedAt,
                page: action.payload.filters.page, 
            }
        case PATIENT_LIST_ACTIONS_MAP.resetAllFiltersStart:
            return {
                ...state,
                filterPending: true,
                limit: initPatientListState.limit,
                offset: initPatientListState.offset,
                globalFilterString: initPatientListState.globalFilterString,
                valueCaseId: initPatientListState.valueCaseId,
                valueCreatedTime: initPatientListState.valueCreatedTime,
                valueDiagnosedAt: initPatientListState.valueDiagnosedAt,
                valueDiagnosedBy: initPatientListState.valueDiagnosedBy,
                valuePatientId: initPatientListState.valuePatientId,
                age: initPatientListState.age,
                dateRange: initPatientListState.dateRange,
                orderCreatedAt: initPatientListState.orderCreatedAt,
                page: initPatientListState.page,
            }
        case PATIENT_LIST_ACTIONS_MAP.updatePageNumberStart:
            return {
                ...state,
                filterPending: true,
                page: action.payload.page,
            }
        case PATIENT_LIST_ACTIONS_MAP.updateCreatedAtOrderStart:
            return {
                ...state,
                filterPending: true,
                orderCreatedAt: action.payload.orderCreatedAt,
            }
        case PATIENT_LIST_ACTIONS_MAP.updateDiagnosedAtValueStart:
            return {
                ...state,
                filterPending: true,
                valueDiagnosedAt: action.payload.valueDiagnosedAt,
            }
        case PATIENT_LIST_ACTIONS_MAP.updateDiagnosedByValueStart:
            return {
                ...state,
                filterPending: true,
                valueDiagnosedBy: action.payload.valueDiagnosedBy,
            }
        case PATIENT_LIST_ACTIONS_MAP.updateAgeValueStart:
            return {
                ...state,
                filterPending: true,
                age: action.payload.age,
            }
        case PATIENT_LIST_ACTIONS_MAP.updateCreatedAtValueDateRangeStart:
            return {
                ...state,
                filterPending: true,
                dateRange: action.payload.dateRange,
            }
        case PATIENT_LIST_ACTIONS_MAP.updateCreatedAtValueStart:
            return {
                ...state,
                filterPending: true,
                valueCreatedTime: action.payload.valueCreatedTime,
            }
        case PATIENT_LIST_ACTIONS_MAP.updatePatientsIdStart:
            return {
                ...state,
                filterPending: true,
                valuePatientId: action.payload.valuePatientId,
            }
        case PATIENT_LIST_ACTIONS_MAP.updatePatientsCaseIdStart:
            return {
                ...state,
                filterPending: true,
                valueCaseId: action.payload.valueCaseId,
            }
        case PATIENT_LIST_ACTIONS_MAP.updateGlobalSearchStart:
            return {
                ...state,
                filterPending: true,
                globalFilterString: action.payload.globalFilterString,
            }
        case PATIENT_LIST_ACTIONS_MAP.updatePageLimitStart:
            return {
                ...state,
                filterPending: true,
                limit: action.payload.limit,
            }
        case PATIENT_LIST_ACTIONS_MAP.updatePageOffsetStart:
            return {
                ...state,
                filterPending: true,
                offset: action.payload.offset,
            }
        case PATIENT_LIST_ACTIONS_MAP.getPatientsStart:
            return {
                ...state,
                patientsLoading: true,
            }
        case PATIENT_LIST_ACTIONS_MAP.getPatientsSuccess:
            return {
                ...state,
                patientsLoading: false,
                patients: action.payload.patients,
                totalCount: action.payload.total,
                subscribePatients: true,
            }
        case PATIENT_LIST_ACTIONS_MAP.updatePageLimitFail:
        case PATIENT_LIST_ACTIONS_MAP.updateGlobalSearchFail:
        case PATIENT_LIST_ACTIONS_MAP.getPatientsFail:
            return {
                ...state,
                patientsLoading: false,
                error: action.payload.error,
                subscribePatients: false,
            }
        default: return state;
    }
}

export default patientListReducer;