import { initPatientListState } from "../reducers/patientListReducer";

export const getPageOffset = (state) => state.patientList.offset; 
export const getPageLimit = (state) => state.patientList.limit; 
export const getPageNumber = (state) => state.patientList.page;
export const getPatientsTotal = (state) => state.patientList.totalCount;
export const getGetPatientsSubscription = (state) => state.patientList.subscribePatients;
export const getPageOrderBy = (state) => state.patientList.orderBy;

export const getAllPatientListFilters = (state) => {
    if (state.patientList) {
        const limit =               state.patientList.limit;
        const offset =              state.patientList.offset;
        const order =               state.patientList.order;
        const page =                state.patientList.page;
        const age =                 state.patientList.age;
        const statuses =            state.patientList.statuses;
        const modifiedAt =          state.patientList.modifiedAt;
        const globalFilterString =  state.patientList.globalFilterString || null;
        const childAgeRestriction = state.patientList.childAgeRestriction;
        const valueCaseId =         state.patientList.valueCaseId;
        const valueCreatedTime =    state.patientList.valueCreatedTime;
        const valueDiagnosedAt =    state.patientList.valueDiagnosedAt;
        const valueDiagnosedBy =    state.patientList.valueDiagnosedBy;
        const valuePatientId =      state.patientList.valuePatientId;
        const dateRange =           state.patientList.dateRange;
        const orderCreatedAt =      state.patientList.orderCreatedAt;   
        return {
            limit, offset, valueDiagnosedAt, valueDiagnosedBy, valuePatientId,
            order, page, statuses, modifiedAt, globalFilterString, childAgeRestriction,
            valueCaseId, valueCreatedTime, dateRange, age, orderCreatedAt,
        };
    } else {
        return {};
    }
};

export const getAllPatientListInitialFilters = () => {
    const limit =               initPatientListState.limit;
    const offset =              initPatientListState.offset;
    const order =               initPatientListState.order;
    const page =                initPatientListState.page;
    const age =                 initPatientListState.age;
    const statuses =            initPatientListState.statuses;
    const modifiedAt =          initPatientListState.modifiedAt;
    const globalFilterString =  initPatientListState.globalFilterString || null;
    const childAgeRestriction = initPatientListState.childAgeRestriction;
    const valueCaseId =         initPatientListState.valueCaseId;
    const valueCreatedTime =    initPatientListState.valueCreatedTime;
    const valueDiagnosedAt =    initPatientListState.valueDiagnosedAt;
    const valueDiagnosedBy =    initPatientListState.valueDiagnosedBy;
    const valuePatientId =      initPatientListState.valuePatientId;
    const dateRange =           initPatientListState.dateRange;
    const orderCreatedAt =      initPatientListState.orderCreatedAt;   
    return {
        limit, offset, valueDiagnosedAt, valueDiagnosedBy, valuePatientId,
        order, page, statuses, modifiedAt, globalFilterString, childAgeRestriction,
        valueCaseId, valueCreatedTime, dateRange, age, orderCreatedAt,
    };
};
