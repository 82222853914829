import * as R from 'ramda'
import { AI_MODEL_PARAM } from '../helpers/ai-models';
import store from '../store'

export const GENDER = Object.freeze({
  MALE: 'male',
  FEMALE: 'female',
})

export const languagesObject = Object.freeze({
  EN: 'en',
  HE: 'he'
})

export const languages = [
  {lang: languagesObject.HE, dir: 'rtl'},
  {lang: languagesObject.EN, dir: 'ltr'}
];
export const language = (state = store.getState()) => {
  const lang = R.pathOr('he', ['userSettings', 'language'])(state.settings)
  return languages.find((item) => item.lang === lang)
};

export function generateAIModelParam(url) {
  const state = store.getState()
  if( state.settings.aiModel ) {
    url += url.indexOf('?') !== -1 ? '&' : '?';
    url += `${AI_MODEL_PARAM}=${state.settings.aiModel}`;
  }
  return url
}

export const getUrlParams = () => {
  return store.getState().router.location.query;
}

export const isRoleMaccabi = () => {
  const state = store.getState()
  const {vendor} = state.settings
  return vendor === 'maccabi' ? true : false
};

// Agreement is that en - keys, he - values
const prefixes = Object.freeze({
    'Dr.':   'דר.',
    'Prof.':  'פרופ.',
    'Mr.': 'מר.'
});

export const getPrefixForLang = (pfx, lang) => {
    if(!pfx || !lang) return '';

    const [key, value] = Object.entries(prefixes).find((values) => {
        return values.includes(pfx);
    });

    return lang === 'en' ? key : value;
};