import { fork, put, takeLatest, select, call, delay} from 'redux-saga/effects'
import {
    CASES_BATCH_ACTIONS_MAP, getDiagnosedCasesBatchRequestStart, getDiagnosedCasesBatchRequestFail,
    getDiagnosedCasesBatchRequestSuccess, blockCasesBatchStart, blockCasesBatchSuccess, blockCasesBatchFail, 
    BLOCK_CASES_RESPONSE, initCasesBatch, getPendingCasesBatchRequest, HOLD_BATCH_ACTIONS, updateIsLoadingSaveCase, updateIsNotifyingCase, updateDiagnosis
} from '../../actions/cases.batch.actions';
import { 
    addNotificationAction, NOTIFICATION_ACTIONS, SYSTEM_ALLOWED_NOTIFICATIONS 
} from '../../actions/notification.actions';
import { SYSTEM_STATUSES } from '../../components/helpers/groups';
import rest, { getCasesBatchByUUID } from '../../services/main.service'
import { 
    getAwaitingCasesList, getAwaitingNewCasesList, getCaseIdSelector, getCurrentIndex,
    getPatientAnnotations, getPatientCreationDate, getPatientTranslated, getPendingDescription,
    getUserIdentifier, 
} from '../../selectors/patient.details.selector';
import { getCurrentPhycisianEmail } from '../../selectors/settings.selector';
import i18n from '../../i18n';
import { copyToClipboard } from '../../components/CaseEdit/helpers/generator.helper';
import { language } from '../../selectors';
import { casesArrayToString } from '../helper';
import { getInnterChr1, getInnterChr2, getInnterComment } from '../../selectors/disease.selector';
import { DIAGNOSE_BLOCK_NAMES, SISEASES_ACTIONS_MAP } from '../../actions/disease.actions';
import { SETTINGS_ACTION_MAP, SETTINGS_LOADING_ACTIONS } from '../../actions/settings.actions';
import { generateUrlParams } from '../../sagas/helper';
import { defaultDiagnoses } from '../../components/CaseEdit/helpers/helper.functions';

const l = (key, params) => i18n.t(key, params);
const AWAITING_CASES_BATCH_STORAGE_KEY = 'AWAITING_CASES_BATCH_STORAGE';
const DIAGNOSED_CASES_BATCH_STORAGE_KEY = 'DIAGNOSED_CASES_BATCH_STORAGE';
const FROZEN_SAVE_ACTION_KEY = 'FROZEN_SAVE_ACTION';

export const ASSIGN_STATUS = Object.freeze({
    ASSIGNED: 'ASSIGNED',
    NOT_ASSIGNED: 'NOT_ASSIGNED',
});

export function* updateCurrentAwaitingListAfterReload (uuid) {
    try {
        const uuidsStr = yield localStorage.getItem(AWAITING_CASES_BATCH_STORAGE_KEY);
        const payload = JSON.parse(uuidsStr);
        if (Array.isArray(payload) && payload.length) {
            yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload });
            const changedCurrentIndex = payload.findIndex(c => c.uuid === uuid);
            if (changedCurrentIndex > -1) {
                yield put({ type: 'UPDATE_CURRENT_CASE_INDEX', payload: changedCurrentIndex });
            }
        }
    } catch (e) {
        console.log(`something wrong with local batch`)
    }
}

function* initCasesBatchWORK(action) {
    try {
        const { uuids } = action.payload;
        yield localStorage.setItem(AWAITING_CASES_BATCH_STORAGE_KEY, JSON.stringify(uuids));
    } catch (e) {
        console.log(`initCasesBatchWORK -> cannot initiate batch.`);
    }
}

function* persistDiagnosedCasesBatch(casesBatch) {
    try {
        yield localStorage.setItem(DIAGNOSED_CASES_BATCH_STORAGE_KEY, JSON.stringify(casesBatch));
    } catch(e) {
        console.log(`persistDiagnosedCasesBatch -> the cases batch was not stored.`);
    }
}

function* initCasesBatchAfterReloadWORK() {
    try {
        const uuidsStr = yield localStorage.getItem(AWAITING_CASES_BATCH_STORAGE_KEY);
        const currentBatchUuids = JSON.parse(uuidsStr);
        if (Array.isArray(currentBatchUuids)) {
            yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload: currentBatchUuids });
        }
    } catch (e) {
        console.log(`initCasesBatchAfterReloadWORK -> failed initialization of awaiting cases?.`);
    }
}

export function* getCurrentCasesBatch() {
    try {
        const uuidsStr = yield localStorage.getItem(AWAITING_CASES_BATCH_STORAGE_KEY);
        return JSON.parse(uuidsStr);
    } catch (e) {
        console.log(`cannot fetch batch`);
        return [];
    }
}

function* add_annotation(action) {
    const {
      case_uuid, case_id, user, currentCasesArray, user_identifier, 
    } = action.payload;
    const data = {
      modified_by: user,
      dermatologist_diagnosis: action.payload  ? action.payload.diagnosis : [],
      dermatologist_annotation: action.payload ? action.payload.comment : '',
      notify: action.payload.notify,
    };
    yield put({type: 'START_ADD_ANNOTATION'});
    const changeToStatus = action.payload.notify ? SYSTEM_STATUSES.DIAGNOSED_NOTIFIED :  SYSTEM_STATUSES.DIAGNOSED;
    let res = yield call(rest.addAnnotation, case_uuid, data);
    let awaitingCasesAvailableArray = currentCasesArray
    .map(d => case_uuid === d.uuid ? {...d, status: SYSTEM_STATUSES.DIAGNOSED } : d);

    const notificationAction = (action.payload.notify) ?
      SYSTEM_ALLOWED_NOTIFICATIONS.ADD_DIAGNOSE_NOTIFY_NOTIFICATION : SYSTEM_ALLOWED_NOTIFICATIONS.ADD_DIAGNOSE_NOTIFICATION;
    
  
    const notificationPayload = {
      action: notificationAction,
      props: {
        caseId: case_id,
        diagnose: action.payload ? action.payload.diagnosis : [],
        comment: action.payload ? action.payload.comment : '',
        date: new Date(),
      },
    };
    
    // batch cases log start
    const casesStr = yield localStorage.getItem(user_identifier);
    if (action.payload && action.payload.notify) {
        const arr = JSON.parse(casesStr);            
        awaitingCasesAvailableArray = awaitingCasesAvailableArray
            .map(c => ({ ...c, status: SYSTEM_STATUSES.DIAGNOSED_NOTIFIED }))
        if (casesStr) {
            
            const payload = {
            action: notificationAction,
                props: {
                    caseId: (action.payload.currentCasesArray && action.payload.currentCasesArray.length > 1) ? casesArrayToString(action.payload.currentCasesArray) : arr.filter(d=> !!d.props).map(d => d.props ? d.props.caseId : '').join(','), 
                    userId: action.payload ? action.payload.user_identifier : '',
                    date: new Date(),
                } 
            };
            yield put(addNotificationAction(payload));
        } else {
            yield put(addNotificationAction(notificationPayload));
        }
        yield localStorage.removeItem(AWAITING_CASES_BATCH_STORAGE_KEY);
        yield localStorage.removeItem(FROZEN_SAVE_ACTION_KEY);
        yield localStorage.removeItem(user_identifier);
    } else {
      if (casesStr) {
        const arr = JSON.parse(casesStr);
        arr.push(notificationPayload);
        yield localStorage.setItem(user_identifier, JSON.stringify(arr));
    } else {
        yield localStorage.setItem(user_identifier, JSON.stringify([notificationPayload]));
    }

}
// batch cases log end
if ((res.data !== rest.ANNOTATION_RESPONSE_TYPES.blocked_case)
    ) {
        yield put({type: 'GET_HISTORY', payload: {user_identifier: action.payload.user_identifier, case_id: case_uuid}});
        yield initCasesBatchWORK({payload: { uuids: awaitingCasesAvailableArray }});
        yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload: awaitingCasesAvailableArray});
        yield put({ type: 'GET_ANNOTATIONS', payload: {caseId: case_uuid} });
        yield put({ type: 'CHANGE_STATUS_STATE', payload: {status: changeToStatus} });
        yield put({type: 'END_ADD_ANNOTATION'});
        yield put(updateIsLoadingSaveCase(false))
        yield put(updateIsNotifyingCase(false))

        if(action.payload.notify) {
            yield put({
                type: NOTIFICATION_ACTIONS.toastOpen,
                payload: { 
                  message: l('patientsDetails.casesBatchNotificationSuccess', { id: case_id }),
                  status: 'info',
                }
            });
        }
        return;
    } else if(res.err) {
        // errored notification
        yield put({type: 'ERROR_ADD_ANNOTATION', payload: {error: 'Annotation added error'}});
        yield put(updateIsLoadingSaveCase(false))
        yield put(updateIsNotifyingCase(false))
        return;
    } else {
    if (res.data === rest.ANNOTATION_RESPONSE_TYPES.blocked_case) {
        yield put({
            type: 'ERROR_ADD_ANNOTATION_ALREADY_TAKEN',
            payload: { error: 'annotation.annotationTakenMessage' },
        });
    }
    // success notification
        yield put({type: 'GET_HISTORY', payload: {user_identifier: action.payload.user_identifier, case_id: case_uuid}});
        yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload: awaitingCasesAvailableArray });
        yield initCasesBatchWORK({payload: { uuids: awaitingCasesAvailableArray }});
        yield put({ type: 'GET_PATIENT_DETAILS', payload: {caseId: case_uuid, user} });
        yield put({ type: 'GET_ANNOTATIONS', payload: {caseId: case_uuid} });
        yield put({ type: 'CHANGE_STATUS_STATE', payload: {status: changeToStatus} });
        yield put({ type: 'END_ADD_ANNOTATION' });
        yield put({ type: SISEASES_ACTIONS_MAP.INIT_DIAGNOSIS });
    }
    console.log('add_annotation -> updateIsLoadingSaveCase')
    yield put(updateIsLoadingSaveCase(false))
    yield put(updateIsNotifyingCase(false))
}

function* getValidatedDiagnosisBeforeSend(action) {
    try {
        const { host } = action.payload || {};
        // const fDiagnose = yield select(s => getDiagnose(s, host, DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE));
        const fDiagnose = action.payload.diagnosis[0];
        const comment1 = yield select(s => getInnterComment(s, host, DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE));
        const chr11 =    yield select(s => getInnterChr1(s, host, DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE));
        const chr21 =    yield select(s => getInnterChr2(s, host, DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE));

        // const sDiagnose = yield select(s => getDiagnose(s, host, DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE));
        const sDiagnose = action.payload.diagnosis[1];
        const comment2 = yield select(s => getInnterComment(s, host, DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE));
        const chr12 =    yield select(s => getInnterChr1(s, host, DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE));
        const chr22 =    yield select(s => getInnterChr2(s, host, DIAGNOSE_BLOCK_NAMES.SECOND_DESEASE));

        // const tDiagnose = yield select(s => getDiagnose(s, host, DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE));
        const tDiagnose = action.payload.diagnosis[2];
        const comment3 = yield select(s => getInnterComment(s, host, DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE));
        const chr13 =    yield select(s => getInnterChr1(s, host, DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE));
        const chr23 =    yield select(s => getInnterChr2(s, host, DIAGNOSE_BLOCK_NAMES.THIRD_DESEASE));
        const isDiagnosed = (d) => d && !!d.text;
        
        return isDiagnosed(fDiagnose) && [
            {...fDiagnose, comment: comment1, characteristic1: chr11, characteristic2: chr21},
            {...sDiagnose, comment: comment2, characteristic1: chr12, characteristic2: chr22},
            {...tDiagnose, comment: comment3, characteristic1: chr13, characteristic2: chr23},
        ];
    } catch(e) {
        console.log(`getValidatedDiagnosisBeforeSend => failed`);
        return false;
    }
}

function* resetCaseData() {
    console.log('here resetCaseData resetCaseData resetCaseData resetCaseData ')
    yield put(updateDiagnosis(defaultDiagnoses, DIAGNOSE_BLOCK_NAMES.FIRST_DESEASE))
    yield put(updateIsLoadingSaveCase(false))
    yield put(updateIsNotifyingCase(false))
}

function* validateIsLastCaseWORK(action) {
    let diagnosisValid = yield getValidatedDiagnosisBeforeSend(action);
    if (!diagnosisValid) {
        diagnosisValid = (action.payload && Array.isArray(action.payload.diff)) ? action.payload.diff : null;
    }
    
    if (diagnosisValid) {
        try {
            const awaitingCases = yield select(getAwaitingCasesList);
            
            const {
                patientDetails: { case_uuid, case_id, caseAvailabilityProps },
                settings: { user },
                patientDetails: { user_identifier }
              } = yield select();
              
            action.payload.case_uuid = case_uuid;
            action.payload.case_id = case_id;
            action.payload.currentCasesArray = awaitingCases;
            action.payload.user_identifier = user_identifier;
            action.payload.user = user;
            
            if (Array.isArray(awaitingCases)) {
                if (!caseAvailabilityProps.isOnlyDiagnosed) {
                    const newCasesArray = yield call(rest.checkBatchNewCases, { 
                        physician: user,
                        user_identifier, 
                        uuids: action.payload.currentCasesArray.map(c => c.uuid),
                    });
                    if (Array.isArray(newCasesArray) && newCasesArray.length) {
                        yield put({
                            type: CASES_BATCH_ACTIONS_MAP.CASE_MERGE_CONFLICT_ON,
                            payload: { awaitingNewCasesArray: newCasesArray }
                        });
                        action.payload.notify = false;
                        yield casesMergeWORK(action);
                        yield add_annotation(action);
                    } else {
                        yield add_annotation(action);    
                    }
                    /*
                        Incoming merge from 5 months ago
                        // action.payload.notify = false;
                        // yield casesMergeWORK(action);
                    */
                    // yield add_annotation(action);
                } else {
                    yield add_annotation(action);
                }
                const pdTranslation = yield select(getPatientTranslated);
                const pdCreationDate = yield select(getPatientCreationDate);
                const fetchedUserIdentifier = yield select(getUserIdentifier);
                const fetchedCaseId = yield select(getCaseIdSelector);
                const patientAnnotations = yield select(getPatientAnnotations);
                const ll = yield select(language);
                copyToClipboard(
                    {
                        patientDetailsTranslated: pdTranslation,
                        created_at: pdCreationDate,
                        user_identifier: fetchedUserIdentifier,
                        case_id: fetchedCaseId,
                    }, patientAnnotations, l, ll,
                    { isBackground: true, isNegative: true, isCurrentDiagnose: true },
                )();
            }
            yield put({ type: SISEASES_ACTIONS_MAP.INIT_DIAGNOSIS });
        } catch (e) {
            console.log(`cannot validate batch...`);
        }
    }
}

function* casesMergeWORK(localAction) {
    try {
        // todo change
        let currentAwaitingCase = yield select(getAwaitingCasesList);
        const awaitingNewCase = yield select(getAwaitingNewCasesList);
        const awaitingNewCaseMap = awaitingNewCase.reduce((acc, val) => {
            acc[`${val.uuid}`] = val;
            return acc;
        }, {});
        const filteredCurrentAwaitingCase = currentAwaitingCase.filter(c => !awaitingNewCaseMap[c.uuid]);
        const awaitingFormatCase = awaitingNewCase.map(c => ({ ...c, caseId: c.id }));
        const batchArray = [...filteredCurrentAwaitingCase, ...awaitingFormatCase];
        const uuidToRedirectTo = awaitingNewCase[0].uuid;
        yield put({ type: CASES_BATCH_ACTIONS_MAP.CASE_MERGE_CONFLICT_OFF });
        const foundIndex = batchArray.findIndex(d => d.uuid === uuidToRedirectTo);
        localAction.payload.notify = false;
        localAction.payload.currentCasesArray = batchArray;
        yield add_annotation(localAction);
        yield put({ type: 'UPDATE_CURRENT_CASE_INDEX', payload: foundIndex });
        yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload: batchArray })
        localAction.payload.history.push(generateUrlParams(`/patients/${uuidToRedirectTo}`));
        
    } catch (e) {}
}

function* pendingCasesBatchRequestWORK(action) {
    try {
        const { uuid } = action.payload;
        const batch_uuids = yield call(rest.getCasesBatchByUUID, uuid);
        const caseRequested = batch_uuids.find(c => c.uuid === uuid);
        yield put({ type: 'CHANGE_STATUS_STATE', payload: { status: !!caseRequested ? caseRequested.status : 2 } })
        if (Array.isArray(batch_uuids) && batch_uuids.length) {
            const payload = batch_uuids.map(c => ({ 
              uuid: c.uuid, 
              status: c.status, 
              caseId: c.id ? c.id : c.case_id, 
              batch_id: c.batch_id
            }));
          yield put(initCasesBatch(payload));
          const foundIndex = batch_uuids.findIndex(d => d.uuid === uuid);
          yield put({ type: 'UPDATE_CURRENT_CASE_INDEX', payload: foundIndex });
          yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload });
        }
    } catch (e) {
        console.log(`diagnosedCasesBatchRequestWORK -> failed to fetch a batch`)
    }
}

function* diagnosedCasesBatchRequestWORK(action) {
    try {
        const { uuid, history } = action.payload;
        let { caseId } = action.payload;
        yield put(getDiagnosedCasesBatchRequestStart(uuid));
        const batch_uuids = yield call(rest.getCasesBatchByUUID, uuid);

        if (Array.isArray(batch_uuids) && batch_uuids.length) {
            const formated = batch_uuids.map(c => ({ ...c, caseId: c.id }))
            yield put(getDiagnosedCasesBatchRequestSuccess(formated, uuid));
            yield persistDiagnosedCasesBatch(formated);
        } else {
            if(!caseId) {
                caseId = yield select(getCaseIdSelector);
            }
            const persisteData = [{ uuid, caseId, status: SYSTEM_STATUSES.DIAGNOSED_NOTIFIED}]
            yield persistDiagnosedCasesBatch(persisteData);
            yield put(getDiagnosedCasesBatchRequestSuccess(persisteData, uuid));
        }
        history && history.push(generateUrlParams(`/patients/${uuid}`));
    } catch (e) {
        yield put(getDiagnosedCasesBatchRequestFail(e));
        console.log(`diagnosedCasesBatchRequestWORK -> failed to fetch a batch`)
    }
}

function* patientNotificationWORK(action) {
    try {
        yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOn, payload: {actionName: SETTINGS_LOADING_ACTIONS.patientNotificationWORK} });
        const { uuid, status, caseId } = action.payload || {};
        if (!uuid || !status) {
            yield put({
                type: NOTIFICATION_ACTIONS.toastOpen,
                payload: { 
                  message: l('patientsDetails.casesBatchNotificationFailed', { id: caseId })
                }
            });
            yield put(updateIsNotifyingCase(false))
            return;
        } else {
            const cases = yield select(getAwaitingCasesList);
            const physician = yield select(getCurrentPhycisianEmail);
            const isNotified = yield call(rest.notifyPatientBatch, {
                cases, physician, activeCase: { uuid: uuid, status: status },
            });
            if (isNotified) {
                const payload = cases.map(c => ({ ...c, status: SYSTEM_STATUSES.DIAGNOSED_NOTIFIED }));
                yield initCasesBatchWORK({ payload: { uuids:  payload} })
                yield put({ type: 'FILL_AWAITING_CASES_ARRAY', payload });
                
                const notificationPayload = {
                    action: SYSTEM_ALLOWED_NOTIFICATIONS.ADD_DIAGNOSE_NOTIFY_NOTIFICATION,
                    props: {
                      caseId: (action.payload.currentCasesArray && action.payload.currentCasesArray.length > 1) ? casesArrayToString(action.payload.currentCasesArray) : caseId,
                      diagnose: null,
                      comment: null,
                      date: new Date(),
                    },
                }; 
                yield put(addNotificationAction(notificationPayload));
                yield put({ 
                    type: 'CHANGE_STATUS_STATE',
                    payload: { status: SYSTEM_STATUSES.DIAGNOSED_NOTIFIED } 
                });
                yield put({
                    type: NOTIFICATION_ACTIONS.toastOpen,
                    payload: { 
                      message: l('patientsDetails.casesBatchNotificationSuccess', { id: caseId }),
                      status: 'info',
                    }
                });
                
                const fetchedUserIdentifier = yield select(getUserIdentifier);
                yield localStorage.removeItem(AWAITING_CASES_BATCH_STORAGE_KEY);
                yield localStorage.removeItem(FROZEN_SAVE_ACTION_KEY);
                yield localStorage.removeItem(fetchedUserIdentifier);
            } else {
                yield put({
                    type: NOTIFICATION_ACTIONS.toastOpen,
                    payload: { 
                      message: l('patientsDetails.casesBatchNotificationFailed', { id: caseId })
                    }
                });  
            }
        }
    } catch (e) {
        yield put({
            type: NOTIFICATION_ACTIONS.toastOpen,
            payload: { 
              message: l('patientsDetails.casesBatchNotificationFailed', { id: action.payload && action.payload.caseId })
            }
        });
        yield put(updateIsNotifyingCase(false))
        console.log('error handler')
    }
    yield put({ type: SETTINGS_ACTION_MAP.loadingGlobalOff, payload: {actionName: SETTINGS_LOADING_ACTIONS.patientNotificationWORK} });
    console.log('updateIsNotifyingCase -> patientNotificationWORK')
    yield put(updateIsNotifyingCase(false))
}

function* blockCasesBatchWORK(act) {
    const { action, description, batchId } = act.payload || {};
    const isActionBlocking  = action === HOLD_BATCH_ACTIONS.blockCases;
    try {
        yield put(blockCasesBatchStart(!isActionBlocking));
        let cases;
        if(batchId) {
            cases = yield call(getCasesBatchByUUID, batchId);
        } else {
            cases = yield select(getAwaitingCasesList);
        }
        const physician = yield select(getCurrentPhycisianEmail);
        const current = yield select(getCurrentIndex)
        let batch_id = '';
        
        const case_uuids = cases.map(c => {
            batch_id = c.batch_id;
            return c.uuid;
        })
        const body = {
            action, physician, case_uuids, 
            batch_id, description, current
        };
        const proccess = yield call(rest.changePendingStatus, body);
        if (proccess === BLOCK_CASES_RESPONSE.forbiddenToBlock) {
            yield put({
                type: NOTIFICATION_ACTIONS.toastOpen,
                payload: { 
                  message: l('casePending.forbiddenToBlock')
                }
            });
            yield put(blockCasesBatchFail(null, !isActionBlocking));
            return;
        }
        
        let descriptions = yield select(getPendingDescription);
        if (action === HOLD_BATCH_ACTIONS.blockCases) {
            const descriptionDump = { physician, date: new Date().toISOString(), description};
            if (!descriptions) {
                descriptions = [descriptionDump];
            } else {
                descriptions.push(descriptionDump);
            }
        }
        yield delay(1000);
        yield put(blockCasesBatchSuccess(descriptions, !isActionBlocking));

        const isInProgress = cases.find(c => c.status === SYSTEM_STATUSES.IN_PROCESS);
        const activeStatus = !!isInProgress ? SYSTEM_STATUSES.IN_PROCESS : SYSTEM_STATUSES.DIAGNOSED;
        yield put({ type: 'CHANGE_STATUS_STATE', payload: { status: isActionBlocking ? SYSTEM_STATUSES.PENDING : activeStatus } })
        yield put(getPendingCasesBatchRequest({ uuid: cases[current].uuid}));
    } catch(e) {
        yield put(blockCasesBatchFail(e, !isActionBlocking));
    }
}

function* initCasesBatchAfterReload() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.INIT_CASES_BATCH_AFTER_RELOAD, initCasesBatchAfterReloadWORK);    
}

function* watchAddAnnotation() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.ADD_ANNOTATION, validateIsLastCaseWORK);
}
  
function* watchCasesNAC() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.INIT_NAC_CASES_BATCH, initCasesBatchWORK);
}

function* watchDiagnosedCasesBatchRequest() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.GET_DIAGNOSED_CASES_BATCH_REQUEST, diagnosedCasesBatchRequestWORK);
}

function* watchPendingCasesBatchRequest() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.GET_PENDING_CASES_BATCH_REQUEST, pendingCasesBatchRequestWORK);
}

function* watchPatientNotification() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.NOTIFY_PATIENT_ON_DIAGNOSED_CASES_REQUEST, patientNotificationWORK);
}

function* watchBlockCasesBatch() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.UPDATE_BLOCK_CASES_BATCH_REQUEST, blockCasesBatchWORK);
}

function* watchResetCaseData() {
    yield takeLatest(CASES_BATCH_ACTIONS_MAP.RESET_CASE_DATA, resetCaseData);
}

const casesBatchSagas = [
    fork(watchCasesNAC),
    fork(watchAddAnnotation),
    fork(watchBlockCasesBatch),
    fork(watchPatientNotification),
    fork(initCasesBatchAfterReload),
    fork(watchPendingCasesBatchRequest),
    fork(watchDiagnosedCasesBatchRequest),
    fork(watchResetCaseData),
];

export default casesBatchSagas;
