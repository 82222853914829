export const ADMIN_ACTIONS_MAP = Object.freeze({
    setAdminSettings: 'SET_ADMIN_SETTINGS',
    resetAdminSettings: 'RESET_ADMIN_SETTINGS',
    updateUserActivity: 'UPDATE_USER_ACTIVITY_REQUEST',
    updateCurrentPhysicianUser: 'UPDATE_CURRENT_PHYSICIAN_ADMIN_PAGE',
    updateCurrentPhysicianName: 'UPDATE_CURRENT_PHYSICIAN_NAME_ADMIN_PAGE',
    updateCurrentPhysicianPhone: 'UPDATE_CURRENT_PHYSICIAN_PHONE_ADMIN_PAGE',
    updatePhysicianNamePrefix: 'UPDATE_PHYSICIAN_NAME_PREFIX',
    updateInvitationalEmail: 'UPDATE_INVITATIONAL_EMAIL',
    updateCurrentPhysicianSignature: 'UPDATE_CURRENT_PHYSICIAN_SIGNATURE_ADMIN_PAGE',
    updateCurrentPhysicianVacationPeriod: 'UPDATE_CURRENT_PHYSICIAN_VACATION_PERIOD_ADMIN_PAGE',
    updateAdminSettingsByPhysician: 'UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_REQUEST',
    updateAdminSettingsByPhysicianSuccess: 'UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_REQUEST_SUCCESS',
    updateAdminSettingsByPhysicianFail: 'UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_REQUEST_FAIL',
    updateDoctorManagementByPhysician: 'UPDATE_DOCTOR_MANAGEMENT_BY_PHYSICIAN',
    savePhysicianInformation: 'SAVE_CHANGE_PHYSICIAN_INFORMATION_REQUEST',
    savePhysicianInformationStart: 'SAVE_CHANGE_PHYSICIAN_INFORMATION_REQUEST_START',
    savePhysicianInformationSuccess: 'SAVE_CHANGE_PHYSICIAN_INFORMATION_SUCCESS',
    savePhysicianInformationFail: 'SAVE_CHANGE_PHYSICIAN_INFORMATION_FAIL',
    // Save physician details
    savePhysicianDetails: 'SAVE_PHYSICIAN_DETAILS_REQUEST',
    savePhysicianDetailsSuccess: 'SAVE_PHYSICIAN_DETAILS_SUCCESS',
    savePhysicianDetailsFail: 'SAVE_PHYSICIAN_DETAILS_FAIL',

    // Save physician stamp
    savePhysicianStampRequest: 'SAVE_PHYSICIAN_STAMP_REQUEST',
    savePhysicianStampSuccess: 'SAVE_PHYSICIAN_STAMP_SUCCESS',
    savePhysicianStampFail: 'SAVE_PHYSICIAN_STAMP_FAIL',

    // Get physician stamp
    getPhysicianStampRequest: 'GET_PHYSICIAN_STAMP_REQUEST',
    getPhysicianStampSuccess: 'GET_PHYSICIAN_STAMP_SUCCESS',
    getPhysicianStampFail: 'GET_PHYSICIAN_STAMP_FAIL',

    setIsVacationActivated: 'PHYSICIAN_VACATION_ACTIOVATION_TRIGGER',
    invitePhysicianRequest: 'SENT_INVITATION_TO_PHYSICIAN_REQUEST',
    invitePhysicianStart: 'SENT_INVITATION_TO_PHYSICIAN_START',
    invitePhysicianSuccess: 'SENT_INVITATION_TO_PHYSICIAN_SUCCESS',
    invitePhysicianFail: 'SENT_INVITATION_TO_PHYSICIAN_FAIL',
    updatePhysicianPrefix: 'UPDATE_PHYSICIAN_PREFIX',
    setRoleMap: 'setRoleMap',
    setLoadingActDeact: 'setLoadingActDeact',
    blockCurrentUser: 'blockCurrentUser',
    logout: ' logout',
    updateCurrentPhysicianAllNames: 'updateCurrentPhysicianAllNames',
    updateScrollvalue: 'updateScrollvalue',
});

export const PHYSICIAN_DEFAULT_NAME_PREFIX = Object.freeze({ namePrefixKey: 'physicianDrTitle', namePrefixValue: 'Dr.' })

export const updatePhysicianNamePrefix = (payload = PHYSICIAN_DEFAULT_NAME_PREFIX) => {
    return {
        type: ADMIN_ACTIONS_MAP.updatePhysicianNamePrefix, payload,
    }
}

export const updateScrollvalueAction = (value) => {
    return { type: ADMIN_ACTIONS_MAP.updateScrollvalue, payload: { value } }
}

export const logoutAction = (history = null) => {
    return { type: ADMIN_ACTIONS_MAP.logout, payload: { history } }
}

export const blockCurrentUserAction = () => {
    return { type: ADMIN_ACTIONS_MAP.blockCurrentUser }
}

export const setLoadingActDeact = (isLoading) => {
    return { type: ADMIN_ACTIONS_MAP.setLoadingActDeact, payload: { loadingActDeact: isLoading } }
}

export const saveInvitationEmailRequest = () => {
    return { type: ADMIN_ACTIONS_MAP.invitePhysicianRequest }
}

export const saveInvitationEmailSuccess = () => {
    return { type: ADMIN_ACTIONS_MAP.invitePhysicianSuccess }
}

export const saveInvitationEmailStart = () => {
    return { type: ADMIN_ACTIONS_MAP.invitePhysicianStart }
}

export const saveInvitationEmailFail = (error) => {
    return { type: ADMIN_ACTIONS_MAP.invitePhysicianFail, payload: { error } }
}

export const setInvitationalEmail = (invitationEmail) => {
    return { type: ADMIN_ACTIONS_MAP.updateInvitationalEmail, payload: { invitationEmail } }
}

export const setActivateVacation = (isVacationSet) => {
    return { type: ADMIN_ACTIONS_MAP.setIsVacationActivated, payload: { isVacationSet } }
}

export const savePhysicianDetailsRequest = (physicianData) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianDetails, payload: { physicianData } }
}

export const savePhysicianDetailSuccess = (data) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianDetailsSuccess, payload: data  }
}

export const savePhysicianDetailsFail = (error) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianDetailsFail, error }
}

export const savePhysicianStampRequest = (file) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianStampRequest, payload: { file } }
}

export const savePhysicianStampSuccess = (data) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianStampSuccess, payload: data  }
}

export const savePhysicianStampFail = (error) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianStampFail, error }
}

export const getPhysicianStampRequest = (fileName) => {
    return { type: ADMIN_ACTIONS_MAP.getPhysicianStampRequest, payload: { fileName } }
}

export const getPhysicianStampSuccess = (link) => {
    return { type: ADMIN_ACTIONS_MAP.getPhysicianStampSuccess, payload: { link }  }
}

export const getPhysicianStampFail = (error) => {
    return { type: ADMIN_ACTIONS_MAP.getPhysicianStampFail, error }
}

export const savePhysicianInformationRequest = (isVacation, isRemoveVacation) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianInformation, payload: { isVacation, isRemoveVacation } }
}

export const savePhysicianInformationRequestStart = () => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianInformationStart }
}

export const savePhysicianInformationSuccess = () => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianInformationSuccess }
}

export const savePhysicianInformationFail = (error) => {
    return { type: ADMIN_ACTIONS_MAP.savePhysicianInformationFail, error }
}

export const updateDoctorManagemtByPhysician = (doctorManagement) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateDoctorManagementByPhysician,
        payload: { doctorManagement }
    }
}

export const updateAdminSettingsByPhysicianRequest = (email) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateAdminSettingsByPhysician,
        payload: { email },
    }
}


export const updateCurrentPhysicianVocation = (vacation) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateCurrentPhysicianVacationPeriod,
        payload: { vacation }
    }
}

export const updateCurrentPhysicianSignature = (signature) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateCurrentPhysicianSignature,
        payload: { signature }
    }
}

export const updateCurrentPhysicianPhone = (phone) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateCurrentPhysicianPhone,
        payload: { phone }
    }
}

export const updateCurrentPhysicianName = ({name, lang, docDetails = null}) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateCurrentPhysicianName,
        payload: { 
            name,
            lang,
            docDetails
        }
    }
}

export const updateCurrentPhysicianAllNames = (names) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateCurrentPhysicianAllNames,
        payload: { 
            names
        }
    }
}

export const updatePhysicianPrefix = (physicianPrefix) => {
    return {
        type: ADMIN_ACTIONS_MAP.updatePhysicianPrefix,
        payload: { physicianPrefix }
    }
}

export const updateCurrentPhysician = (user) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateCurrentPhysicianUser,
        payload: { user }
    }
}

export const updateUserActivity = (isActive) => {
    return {
        type: ADMIN_ACTIONS_MAP.updateUserActivity,
        payload: {isActive}
    }
}


export const resetAdminSettings = () => {
    return { type: ADMIN_ACTIONS_MAP.resetAdminSettings };
}

export const setAdminSettings = (payload) => {
    return {
        type: ADMIN_ACTIONS_MAP.setAdminSettings, payload
    };
}


