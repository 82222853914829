import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography,  Link } from '@material-ui/core';

const Copyright = () => {

    const { t } = useTranslation();

    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {t('loginModal.copyRight')}
            <Link target="_blank" color="primary" href="https://www.dermadetect.com/">
            {t('loginModal.companyName')}
            </Link>
        {' 2021'}
      </Typography>
    );
}

export default Copyright;
