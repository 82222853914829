import React from 'react';
import { SYSTEM_ALLOWED_NOTIFICATIONS } from '../../actions/notification.actions';
import { Typography } from '@material-ui/core';
import {
    Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot
} from '@material-ui/lab';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import CancelIcon from '@material-ui/icons/Cancel';
import Notifications from '@material-ui/icons/Notifications';
import moment from 'moment';
import 'moment/min/locales';
import 'moment/locale/he';

const pfx = 'notificationsTemplate.';

const BatchList = (props) => {
    return <Typography color="textSecondary" variant="caption" noWrap>{props.data.caseId.indexOf(',') !== -1 ? props.translate(pfx +'casesIDs') : props.translate(pfx +'caseID')}: {props.data.caseId}</Typography>
}

const Notification = props => {
    const generalStructLogin = (d, index, title, text, icon) => (
        <TimelineItem key={index}>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    { d.date ? moment(d.date).format('DD-MM-YY | HH:mm:ss') : '' }
                </Typography>
            </TimelineOppositeContent>
            
            <TimelineSeparator>
                <TimelineDot>
                    {icon}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            
            <TimelineContent>
                <>
                    <Typography variant="body1" noWrap>{title}</Typography>
                    <div style={{ width: '300px', display: 'grid' }}>
                        { text }
                    </div>
                </>
            </TimelineContent>
        </TimelineItem>
    )

    const doDiagnosesTemplate = (data) => {
        return Array.isArray(data.diagnose) ? (
            data.diagnose.filter(d => !!d.text)
            .map((d, i) => (
                <Typography color="textSecondary" key={i} variant="caption" noWrap>{`${props.translate(pfx + 'diagnose')} #${(i + 1)}: ${d.text}`}</Typography>
            ))
        ) : null
    }

    const generateNotificationItem = (action, data, index) => {
        switch(action) {
            case SYSTEM_ALLOWED_NOTIFICATIONS.LOGIN_NOTIFICATION:
                const loginText =  <Typography color="textSecondary" variant="caption" noWrap>{props.translate(pfx + 'email')}: {data.user}</Typography>;
                const loginIcon = (<LockOpenIcon fontSize="small" />);
                return generalStructLogin(data, index, 'Login', loginText, loginIcon);
            case SYSTEM_ALLOWED_NOTIFICATIONS.LOGOUT_NOTIFICATION:
                const logoutIcon = (<LockIcon fontSize="small"/>);
                const logoutText = <Typography color="textSecondary" variant="caption" noWrap>{props.translate(pfx + 'email')}: {data.user}</Typography>;
                return generalStructLogin(data, index, 'Logout', logoutText, logoutIcon);
            case SYSTEM_ALLOWED_NOTIFICATIONS.ADD_DIAGNOSE_NOTIFICATION:
                const diagnosedIcon = (<LocalHospitalIcon fontSize="small" color="primary"/>);
                const diagnosedText = (
                    <>
                        <Typography color="textSecondary" variant="caption" noWrap>{props.translate(pfx +'caseID')}: {data.caseId}</Typography>
                        {
                            doDiagnosesTemplate(data)
                        }
                        {
                            (data.comment && data.comment.text) ? (<Typography color="textSecondary" variant="caption" noWrap>{props.translate(pfx + 'comment')}: {data.comment.text}</Typography>) : null
                        }
                    </>
                );
                return generalStructLogin(data, index, props.translate(pfx + 'SavedDiagnose'), diagnosedText, diagnosedIcon);
            case SYSTEM_ALLOWED_NOTIFICATIONS.ADD_DIAGNOSE_NOTIFY_NOTIFICATION:
                const diagnosedNotifiedIcon = (<LocalHospitalIcon fontSize="small" color="primary"/>);
                const diagnosedNotifiedText = (
                    <BatchList
                        data={data}
                        translate={props.translate}
                    />
                );
                
                return generalStructLogin(data, index, props.translate(pfx + 'SavedAndNotifiedDiagnose'), diagnosedNotifiedText, diagnosedNotifiedIcon);
            case SYSTEM_ALLOWED_NOTIFICATIONS.CANCEL_DIAGNOSE_NOTIFICATION:
                const cancelIcon = (<CancelIcon fontSize="small" color="primary"/>);
                const cancelText = <>
                                    <Typography color="textSecondary" variant="caption" noWrap>{props.translate(pfx +'caseID')}: {data.caseId}</Typography>
                                    {
                                        doDiagnosesTemplate(data)
                                    }
                                </>;
                return generalStructLogin(data, index, props.translate(pfx + 'cancelDiagnosed'), cancelText, cancelIcon);
            case SYSTEM_ALLOWED_NOTIFICATIONS.NOTIFICATION_NOTIFY_PATIENT:
                const notifiedIcon = (<Notifications fontSize="small" color="primary"/>);
                const notifiedText =
                    (<Typography color="textSecondary" variant="caption" noWrap>{props.translate(pfx +'caseID')}: {data.caseId}</Typography>)
                return generalStructLogin(data, index, 'Patient Notified', notifiedText, notifiedIcon);
            default: console.log('template was not correct');
        }   
    } 

    const items = props.notifications.map((n, i) => generateNotificationItem(n.action, n.props, i));
    return (
        <Timeline
            align={'left'}
            style={{
                maxWidth: '800px',
                overflowX: 'hidden',
                overflowY: 'auto',
                padding: '15px',
                border: '1px solid #f5f5f5',
                margin: '5px',
            }}
        >
            { (items && items.length) ? items : props.translate(pfx + 'emptyNotifications') }
        </Timeline>
    )
}

export default Notification;