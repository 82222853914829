import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

const ErrorBlock = ({ message }) => {
    return (
        <Card style={{ borderColor: 'red' }} >
            <CardContent>
                <Typography variant="caption" style={{ color: 'red' }}>{message}</Typography>
            </CardContent>
        </Card>

    );
};

export default ErrorBlock;