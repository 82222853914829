import { AI_MODELS } from '../helpers/ai-models';
import { EDoctorPermissions } from '../reducers/settingReducer';

export const getLanguage = (state) => (state.settings && state.settings.userSettings) ?
    state.settings.userSettings.language : 'he';
export const getLanguageDir = (state) => (state.settings && state.settings.userSettings) ?
state.settings.userSettings.dir : 'ltr';
export const loadingGlobalProcess = state => state.settings ? state.settings.isGlobalLoading : false;
export const getCurrentPhycisianEmail = (state) => state.settings ? state.settings.user : null;
export const getCurrentPhycisianPermission = (state) => state.settings ? state.settings.permission : EDoctorPermissions.readOnly;
export const getCurrentPhycisianRoles = (state) => state.settings ? state.settings.userRoles : [];
export const getCurrentVendor = (state) => state.settings ? state.settings.vendor : '';
export const isViewOnly = (state) => state.settings ? state.settings.viewOnly : false;
export const getCurrentAvailableField = (state) => state.settings ? state.settings.isCurrentAvailableFaild : false;
export const getAiModel = (state) => state?.settings?.aiModel ? state.settings.aiModel : AI_MODELS.DEFAULT;