import { all, call, put, fork, select, takeLatest } from "@redux-saga/core/effects";
import { PATIENT_DETAILS_ACTIONS,
  createTreatmentPlanSuccess, createTreatmentPlanFail
} from "../../actions/patient.details.actions";
import { copyIdToClipboard } from "../../components/CaseEdit/helpers/generator.helper";
import { getUserIdentifier } from "../../selectors/patient.details.selector";
import { createTreatmentPlan, loadBlob } from '../../services/main.service';

export function* copyPatientId() {
    let userIdentifier = yield select(getUserIdentifier)
    yield copyIdToClipboard(userIdentifier);
}
const blobToBase64 = (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = function() {
    var dataUrl = reader.result;
    var base64 = dataUrl.split(',')[1];
    resolve(base64);
  };
  reader.readAsDataURL(blob);
});

export function* createTreatmentPlanWORK({ payload }) {
  try {
    const {prescriptionsBlob, referralsBlob, treatmentPlanBlob} = yield all({
      ...( payload.form.prescriptions_pdf && { prescriptionsBlob: call(loadBlob, payload.form.prescriptions_pdf), }),
      ...( payload.form.referrals_pdf && { referralsBlob: call(loadBlob, payload.form.referrals_pdf), }),
      ...( payload.form.treatment_plan_pdf && { treatmentPlanBlob: call(loadBlob, payload.form.treatment_plan_pdf), }),
    });

    const {prescriptions_pdf, referrals_pdf, treatment_plan_pdf} = yield all({
      ...(prescriptionsBlob && {prescriptions_pdf: blobToBase64(prescriptionsBlob)}),
      ...(referralsBlob && {referrals_pdf: blobToBase64(referralsBlob)}),
      ...(treatmentPlanBlob && {treatment_plan_pdf: blobToBase64(treatmentPlanBlob)}),
    });
    payload.form.prescriptions_pdf = prescriptions_pdf;
    payload.form.referrals_pdf = referrals_pdf;
    payload.form.treatment_plan_pdf = treatment_plan_pdf;

    const response = yield call(createTreatmentPlan, payload);
    console.log('>>> response = ', response); // TODO do smt with response
    yield put(createTreatmentPlanSuccess(response));
  } catch (error) {
    console.log('createTreatmentPlanWORK error = ', error);
    yield put(createTreatmentPlanFail(error?.message));
  }
}

export function* watchCopyPatientId() {
    yield takeLatest(PATIENT_DETAILS_ACTIONS.copyPatientId, copyPatientId);
}

export function* watchCreateTreatmentPlan() {
    yield takeLatest(PATIENT_DETAILS_ACTIONS.CREATE_TREATMENT_PLAN_START, createTreatmentPlanWORK)
}

export const patientDetailsSagas = [
    fork(watchCopyPatientId),
    fork(watchCreateTreatmentPlan),
];
