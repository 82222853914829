import moment from 'moment';
import { language, languages } from '.';
import { PHYSICIAN_DEFAULT_NAME_PREFIX } from '../actions/admin.actions';
import { getPrefixForLang } from './index';

const getLang = (state) => language(state).lang

export const getDoctorInfo = (state) => {
  const { degree, license, expert_license, physician_details, stamp_blob_link } = state.admin;
  return {
    degree,
    license,
    expert_license,
    physician_details,
    stamp_blob_link,
  };
}
export const getAiNetworks = (state) => state.admin ?  state.admin.ai_networks : null;
export const getCurrentUser = (state) => state.admin ?  state.admin.user : '';
export const getCurrentPhysicianNamePrefixObject = (state) => state.admin ?  state.admin.namePrefixObject : PHYSICIAN_DEFAULT_NAME_PREFIX;
export const getCurrentAdmin = (state) => state.admin ?  state.admin.admin : '';
export const getScrollValue = (state) => state.admin ?  state.admin.scrollValue : 0;
export const getCurrentUserName = (state) =>  {
    const lang = getLang(state);
    return state.admin[lang]?.name || '';
}
export const getCurrentUserNameAndPrefix = (state) =>  {
    const lang = getLang(state);
    return state.admin && state.admin[lang] ? {prefix: state.admin.physicianPrefix, name: state.admin[lang].name} : {prefix: '', name: ''};
}
export const getUserNameInAllLanguages = (state) =>  {
    let allNames = {}
    for(let i = 0; i < languages.length; i++) {
        let lang = languages[i].lang;
        if(state.admin && state.admin[lang]) {
            allNames[lang] = state.admin[lang].name;
            if(state.admin.name) {
                allNames['general'] = state.admin.name;
            }
        }
    }

    if(state.admin && typeof state.admin.name == 'string') {
        allNames.name = state.admin.name;
    }

    return allNames;
}
export const getCurrentVacation = (state) => state.admin ?  state.admin.vacation : '';
export const getDoctorManagement = (state) => state.admin ?  state.admin.doctorManagement : {};
export const getCurrentUserPhone = (state) => state.admin ?  state.admin.phone : '';
export const getCurrentSignature = (state) => state.admin ?  state.admin.signature : '';
export const getCurrentStamp = (state) => state.admin ?  state.admin.stamp : '';
export const getCurrentStampBlobLink = (state) => state.admin ?  state.admin.stamp_blob_link : '';
export const getClinicPhone = (state) => state.admin ?  state.admin.clinic_phone : '';
export const getClinicAddress = (state) => state.admin ?  state.admin.clinic_address : '';
export const getIsCurrentUserActive = (state) => state.admin ?  state.admin.isActive : true;
export const getSupperUserEmployees = (state) => state.admin ?  state.admin.employees : [];
export const getCurrentUserPermission = (state) => state.admin ? state.admin.permission : '';
export const getCurrentUserDegree = (state) => state.admin ? state.admin.degree : '';
export const getCurrentUserLicense = (state) => state.admin ? state.admin.license : '';
export const getCurrentUserExpertLicense = (state) => state.admin ? state.admin.expert_license : '';
export const getCurrentUserDetails = (state) => state.admin ? state.admin.physician_details : '';
export const getIsAdminChangesLoading = (state) => state.admin ? state.admin.isAdminChangesLoading : false;
export const getIsSignatureLoading = (state) => state.admin ? state.admin.isSignatureLoading : false;
export const getIsVacationSet = (state) => state.admin ? state.admin.isVacationSet : false;
export const getInvitationEmail = (state) => state.admin ? state.admin.invitationEmail : '';
export const getRoleMap = (state) => state.admin ? state.admin.roleMap : {};
export const getInvitationLoading = (state) => state.admin ? state.admin.isInvitationLoading : false;
export const getPhysicianPrefix = (state) => state.admin ? state.admin.physicianPrefix : '';
export const getPhysicianPrefixByLang = (state) => {
    const res = state.admin?.physicianPrefix ? getPrefixForLang(state.admin.physicianPrefix, state.settings.userSettings.language) : '';
    return res;
};
export const getLoadingActDeact = (state) => state.admin ? state.admin.loadingActDeact : '';

export const isCurrentUserInVacation = (state) => {
    if (getIsVacationSet(state)) {
        const valationArr = getCurrentVacation(state);
        if (!Array.isArray(valationArr)) {
            return false;
        }
        const { startDate, endDate } = valationArr[0];
        if (!startDate || !endDate) {
            return false;
        }
        const sd = moment(startDate).format();
        const ed = moment(endDate).add(1, 'd').format();
        const result = moment().isBetween(sd, ed);
        return result;
    } else {
        return false;
    }
}

export const getVacationsSafely = (state) => {
    return state.admin.vacation;
}

export const fetchImagePermission = state => {
    const settings = state.settings;
    const admin = state.admin;
    const user = settings.user;
    const management = admin.doctorManagement || {};
    const adminSettings = user && management[user] && management[user].admin_settings;
    try {
        if (adminSettings) {
            return adminSettings.showImages;
        } else {
            return false;
        }
    } catch (e) {
        console.log(`cannot fetch admin settings showImages.`);
        return false;
    }
}