import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Typography, makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


const useStyles = makeStyles(theme => ({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  }));


const LoginHeader = ({ title, subtitle }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {t(`${title}`).toUpperCase()}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {t(`${subtitle}`)}
            </Typography>

        </>
    );
};

export default LoginHeader;