
export const AUTH_ACTIONS_MAP = Object.freeze({
    isReceivedRegistrationParametersValid: 'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS',
    isReceivedRegistrationParametersValidStart: 'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_START',
    isReceivedRegistrationParametersValidSuccess: 'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_SUCCESS',
    isReceivedRegistrationParametersValidFail: 'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_FAIL',
    savePhysicianPassword: 'SAVE_NEW_PHYSICIAN_PASSWORD',
    savePhysicianPasswordStart: 'SAVE_NEW_PHYSICIAN_PASSWORD_START',
    savePhysicianPasswordSuccess: 'SAVE_NEW_PHYSICIAN_PASSWORD_SUCCESS',
    savePhysicianPasswordFail: 'SAVE_NEW_PHYSICIAN_PASSWORD_FAIL',
    savePhysicianPasswordInvalid: 'SAVE_NEW_PHYSICIAN_PASSWORD_INVALID',
    createNewPasswordRequest: 'CREATE_NEW_PASSWORD_REQUEST',
    createNewPasswordStart: 'CREATE_NEW_PASSWORD_START',
    createNewPasswordSuccess: 'CREATE_NEW_PASSWORD_SUCCESS',
    createNewPasswordFail: 'CREATE_NEW_PASSWORD_FAIL',
    resetCreateNewPasswordError: 'RESET_CREATE_NEW_PASSWORD_ERROR',
    resetPasswordRequest: 'RESET_PASSWORD_REQUEST',
    resetPasswordSucces: 'RESET_PASSWORD_SUCCESS',
    resetPasswordStart: 'RESET_PASSWORD_START',
    resetPasswordFail: 'RESET_PASSWORD_FAIL',
    doLoginProcess: 'DO_LOGIN_PROCESS',
    logoutFromAnalytics: "logoutFromAnalytics",
    loginToAnalytics: 'loginToAnalytics',
    setCSRFToken: 'setCSRFToken',
    updateCSRFToken: 'updateCSRFToken',
    removeCSRFToken: 'removeCSRFToken',
});

export const RESET_PASSWORD_ACTIONS = Object.freeze({
    SENT_CONFIRMED: 'SENT_CONFIRMED',
    DENIED: 'DENIED',
});

// export const doLogin = (payload) => ({
//     type: AUTH_ACTIONS_MAP.doLoginProcess, paylaod, 
// });

export const removeCSRFTokenAction = () => {
    return {
        type: AUTH_ACTIONS_MAP.removeCSRFToken
    }
}

export const updateCSRFTokenAction = () => {
    return {
        type: AUTH_ACTIONS_MAP.updateCSRFToken
    }
}

export const setCSRFToken = (token) => {
    return {
        type: AUTH_ACTIONS_MAP.setCSRFToken,
        payload: { token }
    }
}

export const createNewPasswordRequest = ({validation_code, new_password, history}) => {
    return {
        type: AUTH_ACTIONS_MAP.createNewPasswordRequest,
        payload: { validation_code, new_password, history }
    }
}

export const createNewPasswordStart = () => {
    return {
        type: AUTH_ACTIONS_MAP.createNewPasswordStart
    }
}

export const createNewPasswordSuccess = (action) => {
    return {
        type: AUTH_ACTIONS_MAP.createNewPasswordSuccess,
        payload: { action }
    }
}

export const createNewPasswordFail = (error) => {
    return {
        type: AUTH_ACTIONS_MAP.createNewPasswordFail,
        payload: { error }
    }
}

export const resetCreateNewPasswordError = (error) => {
    return {
        type: AUTH_ACTIONS_MAP.resetCreateNewPasswordError,
    }
}

export const resetPasswordRequest = (email, history) => {
    return {
        type: AUTH_ACTIONS_MAP.resetPasswordRequest,
        payload: { email, history }
    }
}

export const resetPasswordStart = () => {
    return {
        type: AUTH_ACTIONS_MAP.resetPasswordStart
    }
}

export const resetPasswordSuccess = (action) => {
    return {
        type: AUTH_ACTIONS_MAP.resetPasswordSucces,
        payload: { action }
    }
}

export const resetPasswordFail = (error) => {
    return {
        type: AUTH_ACTIONS_MAP.resetPasswordFail,
        payload: { error }
    }
}

export const savePhysicianPassword = (email, password, history) => {
    return {
        type: AUTH_ACTIONS_MAP.savePhysicianPassword,
        payload: { password, email, history },
    }
}

export const savePhysicianPasswordStart = () => {
    return {
        type: AUTH_ACTIONS_MAP.savePhysicianPasswordStart,
    }
}

export const savePhysicianPasswordSuccess = () => {
    return {
        type: AUTH_ACTIONS_MAP.savePhysicianPasswordSuccess,
    }
}

export const savePhysicianPasswordInvalid = () => {
    return {
        type: AUTH_ACTIONS_MAP.savePhysicianPasswordInvalid,
   }
}

export const savePhysicianPasswordFail = (error) => {
    return {
        type: AUTH_ACTIONS_MAP.savePhysicianPasswordFail,
        payload: { error }
    }
}

export const validateReceivedRegistrationParameters = (email, code) => {
    return {
        type: AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValid,
        payload: { email, code },
    }
}
export const validateReceivedRegistrationParametersStart = () => {
    return {
        type: AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValidStart,
    }
}
export const validateReceivedRegistrationParametersSuccess = () => {
    return {
        type: AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValidSuccess,
    }
}
export const validateReceivedRegistrationParametersFail = (error) => {
    return {
        type: AUTH_ACTIONS_MAP.isReceivedRegistrationParametersValidFail,
        payload: { error },
    }
}
