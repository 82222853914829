/**
 *  /settings/ Reducer
 */
import * as moment from 'moment';
import * as R from 'ramda';
import i18n from '../i18n';
import { SETTINGS_ACTION_MAP } from '../actions/settings.actions';
import { getDir } from '../helpers/route.helper';

const initialState = {
  user: null,
  userSettings: null,
  userRoles: [],
  vendor: '',  //'yeledoctor',
  loginProcess: false,
  permission: '',
  lightboxShow: false,
  image: null,
  isCurrentAvailableFaild: false,
  isGlobalLoading: false,
  socketId: '',
  isScreenBlocked: false,
  viewOnly: false,
  aiModel: null,
};

export const changeLng = async (lng) => {
  await i18n.changeLanguage(lng);
};

export const EDoctorPermissions = Object.freeze({
  readOnly: 'read_only',
  admin: 'admin',
  user: 'user',
  superuser: 'superuser'
});

const loadingComponents = []

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_ACTION_MAP.socketConnectionRequestSuccess:
      return {
        ...state,
        socketId: action.payload.id,
      }

    case SETTINGS_ACTION_MAP.setAiModel:
      return {
        ...state,
        aiModel: action.payload.aiModel,
      }
    case SETTINGS_ACTION_MAP.setViewOnly:
      return {
        ...state,
        viewOnly: action.payload.viewOnly,
      }
    case SETTINGS_ACTION_MAP.socketConnectionRequestFail:
      return {
        ...state,
        socketId: '',
      }
    case SETTINGS_ACTION_MAP.loadingGlobalOff:
      const index = loadingComponents.indexOf(action.payload.actionName);

      if (index !== -1) {
        loadingComponents.splice(index, 1);
      }

      if(!loadingComponents.length) {
        return {
            ...state,
            isGlobalLoading: false,
        }
      }
      return {
        ...state,
      }

    case SETTINGS_ACTION_MAP.loadingGlobalOn:
      if(loadingComponents.indexOf(action.payload.actionName) === -1) {
        loadingComponents.push(action.payload.actionName)
      }

      return {
          ...state,
          isGlobalLoading: true,
      }
    case 'SET_USER': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'SET_FAILED_AVAILABLE_CASES':
      return {
        ...state,
        isCurrentAvailableFaild: action.payload
      }
    case 'SET_LANGUAGE': {
      i18n.changeLanguage(action.payload);
      moment.updateLocale(action.payload);
      return R.assocPath(['userSettings', 'language'], action.payload)(state);
    }
    case 'SET_USER_SETTINGS': {
      const userSettings = action.payload;
      const lng = userSettings?.language ?? 'he';
      moment.updateLocale(lng);
      return {
        ...state,
        userSettings: {...userSettings, dir: getDir(lng)},
      };
    }
    case 'SET_USER_ROLES' : {
      return {
        ...state,
        userRoles: action.payload
      }
    }
    case 'SET_USER_LANGUAGE': {
      // return R.assocPath(['userSettings', 'language'], action.payload)(state)
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          language: action.payload,
          dir: getDir(action.payload),
        }
      }
    }
    case 'SET_START_LOGIN_PROCESS': {
      return {
        ...state,
        loginProcess: true,
      };
    }
    case 'SET_END_LOGIN_PROCESS': {
      return {
        ...state,
        loginProcess: false,
      };
    }
    case 'OPEN_LIGHTBOX': {
      return {
        ...state,
        lightboxShow: true,
        ...action.payload,

      }
    }
    case 'CLOSE_LIGHTBOX': {
      return {
        ...state,
        lightboxShow: false,
        images: null
      }
    }

    default: {
      return state;
    }
  }
}
