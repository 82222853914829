export const caseIdUrlParam = ':caseId';
export const messageParam = 'message'
export const titleParam = 'title'
export let ROUTES = {
    LOGIN: '/login',
    PATIENTS_LIST: '/patients',
    PATIENTS_CASE_EDIT: '',
}
ROUTES.PATIENTS_CASE_EDIT = `${ROUTES.PATIENTS_LIST}/${caseIdUrlParam}`;
ROUTES = Object.freeze(ROUTES);

export function getCaseUrl(caseUuid, fullUrl = false) {
    const path = ROUTES.PATIENTS_CASE_EDIT.replace(caseIdUrlParam, caseUuid);
    return fullUrl ? `${window.location.origin}${path}` : path;
}

export function isPage(route) {
    if(ROUTES.PATIENTS_CASE_EDIT === route) {
        const urlPathParts = window.location.pathname.split('/');

        if(window.location.pathname.indexOf(ROUTES.PATIENTS_LIST) !== -1 && urlPathParts[urlPathParts.length - 1] !== ROUTES.PATIENTS_LIST.replace('/', '')) {
            return true
        }
    } else {
        return window.location.pathname.indexOf(route) !== -1;
    }
}
export function getDir(lng) {
  const langs = {
    en: 'ltr',
    he: 'rtl',
    // ru: 'ltr',
  }
  return langs?.[lng] ?? 'ltr';
}