import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIDPService } from '../../selectors/auth.selector';
import {
  Button,
  Container,
  Typography,
  CssBaseline,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
} from '@material-ui/core';
import CustomInput from '../shared/CustomInput';
import ErrorBlock from '../shared/ErrorBlock';
import LoginHeader from '../shared/LoginHeader';
import * as R from 'ramda';
import * as serverAuth from '../../services/auth.service';
import WebSocketService from '../../services/WebSocketService';
import { apiFullURL } from '../../core/axios';
import { useDispatch } from 'react-redux';
import { changeLng } from '../../reducers/settingReducer';
import { SYSTEM_ALLOWED_NOTIFICATIONS, addNotificationAction } from '../../actions/notification.actions';
import { setAdminSettings } from '../../actions/admin.actions';
import { DEFAULT_VACATION } from '../../reducers/adminReducer';
import Copyright from '../shared/CopyRight';
import { NavLink, useLocation } from 'react-router-dom';
import { generateUrlParams } from '../../sagas/helper';
import { AUTH_ACTIONS_MAP, updateCSRFTokenAction } from '../../actions/auth.actions';
import {
  AUTHENTICATED_RESPONSE,
  NOT_AUTHENTICATED_RESPONSE,
  BLOCKED_NOT_ACTIVE,
  BLOCKED_ON_VACATION,
  TOO_MANY_REQUESTS_RESPONSE
} from './constants';


const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

let isIDPServiceUpdated = false;

const LoginPage = (props) => {

    const validate = (err) => {
      if (!err) {
        return;
      }

      let messageLocation = 'loginModal.error';
      switch(err) {
        case BLOCKED_NOT_ACTIVE:
          messageLocation = 'loginModal.physicianBlocked';
          break;
        case BLOCKED_ON_VACATION:
          messageLocation = 'loginModal.physicianVacation';
          break;
        case TOO_MANY_REQUESTS_RESPONSE:
          messageLocation = 'loginModal.errorTooManyRequest';
          break;
        default: messageLocation = 'loginModal.error';
      }

      setInvalid(true);
      setPasswordInvalid(true);
      setInvalidMessage(t(messageLocation));
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const query = useQuery();

    const [user, setUser] = React.useState(query.get('email') || '');
    const [password, setPassword] = React.useState(query.get('password') || '');
    const [passwordInvalid, setPasswordInvalid] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [invalid, setInvalid] = React.useState(false);
    const [invalidMessage, setInvalidMessage] = React.useState(false);
    const IDPService = useSelector(getIDPService);

    React.useEffect(() => {
      dispatch(updateCSRFTokenAction());
    }, [
      passwordInvalid,
      error, invalid,
      invalidMessage,
      IDPService,
      dispatch
    ])


    React.useEffect(() => {
        if(isIDPServiceUpdated) {
          return;
        }
        // dispatch({ type: SETTINGS_ACTION_MAP.loadingGlobalOff });
        serverAuth.getEnvSettings()
        .then(async (envSettings) => {
            dispatch({type: 'SET_ENV_SETTINGS', payload: envSettings})
            isIDPServiceUpdated = true;
        })
    }, [
      passwordInvalid,
      error, invalid,
      invalidMessage,
      IDPService,
      dispatch
    ])

    const isIdpServiceLocal = IDPService && IDPService === serverAuth.IDPServiceTypes.LOCAL;

    const redirectAfterLogin = ({is_validation_code, is_validated, history}) => {
      if (is_validation_code) {
        history.push(generateUrlParams('/create-password'))
      } else {
        is_validated ? history.push(generateUrlParams('/patients')) : history.push(generateUrlParams('/user-management'));
      };
    };

    const onLoginHandler = async () => {
        dispatch({type: 'SET_START_LOGIN_PROCESS'});
        try {
          const userData = await serverAuth.login({ email: user, password: password });

          if(!isIdpServiceLocal) {
            window.location.href = generateUrlParams(userData.loginUrl);
            return;
          }

          if (userData && userData.status === AUTHENTICATED_RESPONSE) {
            if (!WebSocketService.socket) {
              WebSocketService.socket = apiFullURL;
            }
            WebSocketService.dispatch = dispatch;
            WebSocketService.history = props.history;
            WebSocketService.fillPropertiesAfterConnection();
            WebSocketService.email = user;
            const language = userData.settings ? userData.settings.language : 'he';
            const newDirection = language === 'he' ?  'rtl' : 'ltr';
            const bodyElem = document.getElementById('lang_direction');
            bodyElem.setAttribute('dir', newDirection);
            localStorage.setItem('access_token', userData.token)
            dispatch({ type: 'SET_USER', payload: {
              ...userData,
              user: userData.doctor_email,
              vendor: userData.vendor_id,
              permission: userData.permission,
              token: null
            }});
            let isVacationSet = false;
            let vacation = DEFAULT_VACATION;
            if (userData.admin_settings && userData.admin_settings.vacation) {
              const defDate = new Date().toISOString();
              const { start_vacation = defDate, end_vacation = defDate} = userData.admin_settings.vacation || {};
              isVacationSet = true;
              vacation = [{
                startDate: new Date(start_vacation),
                endtDate: new Date(end_vacation),
              }]
            }
            const employees = userData.roleMap ? Object.keys(userData.roleMap) : [];
            dispatch(setAdminSettings({
              adminSettings: userData.admin_settings,
              admin: userData.doctor_email,
              user: userData.doctor_email,
              is_active: userData.is_active,
              is_validated: userData.is_validated,
              employees: employees,
              permission: userData.permission,
              vacation, isVacationSet,
              roleMap: userData.roleMap,
            }));
            const payload = { action: SYSTEM_ALLOWED_NOTIFICATIONS.LOGIN_NOTIFICATION,
                props: {
                    permission: userData.permission,
                    user: userData.doctor_email,
                    date: new Date(),
                }
            };
            dispatch(addNotificationAction(payload));
            dispatch({type: 'SET_USER_SETTINGS', payload: userData.settings})
            const lng = R.propOr('he', 'language')(userData.settings);
            changeLng(lng);
            dispatch({type: 'SET_USER_ROLES', payload: employees})
            dispatch({type:'LOAD_DISEASES', payload: userData.vendor_id });
            dispatch({type: 'LOAD_TEMPLATES', payload: userData.doctor_email});
            dispatch({type: AUTH_ACTIONS_MAP.loginToAnalytics});
            redirectAfterLogin({
              is_validation_code: userData.is_validation_code,
              is_validated: userData.is_validated,
              history: props.history
            });
          }else {

            switch((userData && userData.status) ? userData.status : userData) {
              case BLOCKED_NOT_ACTIVE:
                validate(BLOCKED_NOT_ACTIVE);
                break;
              case BLOCKED_ON_VACATION:
                validate(BLOCKED_ON_VACATION);
                break;
              case NOT_AUTHENTICATED_RESPONSE:
                validate({});
                break;
              default:
                validate(TOO_MANY_REQUESTS_RESPONSE);
                break
            }
            setError('Authenticate error');
          }
        } catch(err) {
          validate(err)
          dispatch({type: 'SET_END_LOGIN_PROCESS'});
        }
    }

      const onDirty = () => {
          setInvalid(false);
          setInvalidMessage('');
          setPasswordInvalid(false);
      }

      const onChangePassword = (event) => {
        const value = event.target.value;
        onDirty();
        setPassword(value);
      }

      const onChangeUser = (event) => {
        const value = event.target.value;
        onDirty();
        setUser(value);
      }

      const inputs = [
        {name: 'user', label: 'loginModal.user', type: 'email', onChangeHandler: onChangeUser, value: user },
        {name: 'password', label: 'loginModal.password', type: 'password', onChangeHandler: onChangePassword, value: password}
      ];

    return (
        <>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box className={classes.paper}>
                <LoginHeader
                  title={'loginModal.title'}
                  subtitle={'loginModal.activeDirectoryDescription'}
                />

                <Box className={classes.form}>
                    {isIdpServiceLocal && inputs.map(({name, label, type, onChangeHandler, value}) => (
                      <CustomInput
                        autoFocus={name === 'user'}
                        key={name}
                        name={name}
                        label={label}
                        value={value}
                        type={type}
                        onChangeHandler={onChangeHandler}
                        onKeyPressHandler={onLoginHandler}
                      />
                    ))}
                    {isIdpServiceLocal &&
                      <Grid container alignItems="center">
                        <Grid item xs={5}>
                          <FormControlLabel
                            disabled
                            control={
                              <Checkbox
                                value="remember"
                                color="primary" />}
                                label={
                                  (
                                    <Typography variant="caption" noWrap>
                                      {t('loginModal.rememberMe')}
                                    </Typography>
                                  )
                                }
                          />
                        </Grid>
                      </Grid>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onLoginHandler}
                    >
                    {t('loginModal.btnLogin')}
                    </Button>

                    {invalid &&
                      <ErrorBlock message={invalidMessage}/>
                    }

                    {isIdpServiceLocal &&
                      <Grid container alignItems="center">
                        <Grid item xs={3}>
                          <NavLink color="primary" to="/forgot-password">
                              <Typography variant="caption" noWrap>
                                {t('loginModal.forgotPassword')}
                              </Typography>
                          </NavLink>
                        </Grid>
                      </Grid>}
                  </Box>
            </Box>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </>
    )
}

export default LoginPage;