
import React from 'react';
import {
  AppBar, Toolbar, Typography, Button, IconButton, Badge, Popover,
} from '@material-ui/core';
import './sidebar.css';
import { getIDPService } from '../../selectors/auth.selector';
import Logo from '../shared/Logo';
import { withTranslation } from 'react-i18next'
import imgEnglish from './britFlag.png'
import imgHebrew from './hebFlag.png'
import { connect } from 'react-redux'
import { language } from '../../selectors'
import packageJson from '../../../package.json';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {
  SYSTEM_ALLOWED_NOTIFICATIONS, NOTIFICATION_ACTIONS, triggerNotification,
} from '../../actions/notification.actions'
import Notification from '../notificationsTemplate/index';
import SettingsIcon from '@material-ui/icons/Settings';
import * as authService from '../../services/auth.service';
import { resetAdminSettings } from '../../actions/admin.actions';
import { generateUrlParams } from '../../sagas/helper';
import { AUTH_ACTIONS_MAP, removeCSRFTokenAction } from '../../actions/auth.actions';

class SideBar extends React.Component {

  state = {
    anchor: null,
    direction: 'rtl',
  }

  componentDidMount() {
    const elem = document.getElementById('lang_direction');
    const dir = elem.getAttribute('dir');
    if (!dir && this.props.language) {
      const newDirection = this.props.language.lang === 'he' ?  'rtl' : 'ltr';
      const bodyElem = document.getElementById('lang_direction');
      bodyElem.setAttribute('dir', newDirection);
      this.setState(prev => ({ ...prev, direction: newDirection }));
    } else if (this.state.direction !== dir) {
      this.setState(prev => ({ ...prev, direction: dir }));
    }
  }

  setLang = (lng, dir) => {
      const bodyElem = document.getElementById('lang_direction');
      bodyElem.setAttribute('dir', dir);
      this.setState(prev => ({ ...prev, direction: dir }));
      this.props.dispatch({type: 'SET_USER_LANG', payload: {user: this.props.user, language: lng} });
  }

  reload = () => {
    this.props.history.push(generateUrlParams(`/patients`));
  }

  logout = () => {
    return authService.logout().then((data) => {
      this.props.dispatch(removeCSRFTokenAction());
      this.props.dispatch({
        type: NOTIFICATION_ACTIONS.addNotification,
        payload: { action: SYSTEM_ALLOWED_NOTIFICATIONS.LOGOUT_NOTIFICATION, props: {
          permission: this.props.permission,
          user: this.props.user,
          date: new Date(),
        }}
      });
      this.props.dispatch({ type: 'SET_USER',
        payload: {
          user: '', vendor: '', permission: '', admin_settings: {}
        }
      });
      this.props.dispatch({type: AUTH_ACTIONS_MAP.logoutFromAnalytics});
      this.props.dispatch(resetAdminSettings());
      localStorage.removeItem('access_token');

      this.props.history.push(generateUrlParams('/login'));
    });
  }

  onClickLogout = () => {
    this.props.dispatch({
        type: NOTIFICATION_ACTIONS.setBlockHistoryCallback,
        payload: {
            historySuccessCallback: () => {
              this.props.dispatch({
                    type: NOTIFICATION_ACTIONS.unblockHistory,
                });
            },
        }
    });
  }

  renderAppBar = () => {
    const {
      dispatch,
      history,
      historyMessage,
      isHistoryBlocked,
      language,
      location,
      notifications,
      t,
      user,
      userSettings
    } = this.props

    const arrUrl = location?.pathname.split('/');
    const isNotificationShown = (Array.isArray(arrUrl) && arrUrl[1] && arrUrl[1] !== 'login');
    const badgeNotifications = Array.isArray(notifications) ?
      (notifications.filter(n => !n.viewed).length)  : 0;
    const popupTransLocationObj = {
      vertical: 'top',
      horizontal: (this.state.direction === 'ltr' ?  'left' : 'right'),
    };
    const popupAnchorLocationObj = {
      vertical: 'bottom',
      horizontal: (this.state.direction === 'ltr' ?  'right' : 'left'),
    };
    return (
      <Toolbar className='toolbar' variant="dense">
        <div className='f1-block'>
          <Logo />

          <AppBarLink to={ !!user ? '/patients' : '/login' } label={t('sidebar.home')} onClick={this.onClickLogout} />
          {userSettings?.isAdmin && <AppBarLink to='/admin' label={t('sidebar.admin')} />}
          {userSettings?.showTemplates && <AppBarLink to='/templates' label={t('sidebar.templates')} />}
        </div>
        <div className='f1-block' style={{ justifyContent: 'flex-end' }}>
          {user ?
            (
              <>
                <AppBarLink
                  to='/'
                  label={t('sidebar.logout')}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (isHistoryBlocked) {
                      dispatch({
                        type: NOTIFICATION_ACTIONS.blockHistory,
                        payload: {
                          isHistoryBlocked: true,
                          message: historyMessage,
                          title: historyMessage,
                          redirectTo: '/login',
                          historySuccessCallback: async () => await this.logout(),
                        }
                      });

                      this.reload();
                      return;
                    }

                    this.logout();
                  }}
                />
                <Typography className='email' color="inherit">{user}</Typography>
              </>
            ) : (
              <AppBarLink to='/login' label={t('sidebar.login')} />
            )
          }

        </div>
        <div className="downSidebar ml-mr">
          {
            isNotificationShown ? (
              <>
                 <IconButton
                    aria-label="notification-bell"
                    color="primary"
                    className="m-1"
                    style={{outline: 'none'}}
                    onClick={e => {
                      let event = e.currentTarget;
                      dispatch(triggerNotification());
                      this.setState(prev => ({ ...prev, anchor: event }));
                    }}
                  >
                  <NotificationsNoneIcon color="primary" fontSize="default"/>
                  <Badge
                    badgeContent={badgeNotifications}
                    color="error"
                    style={{ position: 'absolute', top: 12, right: 12 }}
                  />
                </IconButton>
                <Popover
                  open={this.props.isOpenNotification}
                  anchorEl={this.state.anchor}
                  onClose={() => {
                    dispatch(triggerNotification());
                    this.setState(prev => ({...prev, anchor: null }));
                  }}
                  style={{ maxHeight: '500px' }}
                  anchorOrigin={popupAnchorLocationObj}
                  transformOrigin={popupTransLocationObj}
                >
                  <Notification
                    notifications={this.props.notifications}
                    translate={t}
                  />
                </Popover>
                {language.lang === 'he' &&
                  <IconButton className='btn-without-outline' color="inherit" onClick={() => this.setLang('en', 'ltr')}>
                    <img className="langFlag" src={imgEnglish} alt='_ENGLISH_' width={30} />
                  </IconButton>
                }
                {language.lang === 'en' &&
                  <IconButton className='btn-without-outline' color="inherit" onClick={() => this.setLang('he', 'rtl')}>
                    <img className="langFlag" src={imgHebrew} alt='_HEBREW_' width={30} />
                  </IconButton>
                }

                <IconButton
                      aria-label="notification-bell"
                      color="primary"
                      className="m-1"
                      style={{outline: 'none'}}
                      onClick={() => {
                        history.push(generateUrlParams('/user-management'));
                      }}
                    >
                    <SettingsIcon color="primary" fontSize="default"/>
                  </IconButton>
              </>
            ) : null
          }
          {
            <Typography className='version' color="inherit">{window.VERSION || packageJson.version}</Typography>
          }
        </div>
      </Toolbar>
    )
  }

  render() {
    return (
      <AppBar color={'default'} style={{ zIndex: 900 }} >
        { this.renderAppBar() }
      </AppBar>
    )
  }
}

function AppBarLink({ label, to, onClick }) {
  return (
    <Link
      className="link-sidebar center-align ml-mr"
      to={generateUrlParams(to)}
      onClick={onClick}>
      <Button
        color="primary"
        style={{outline: 'none'}}
      >
        {label}
      </Button>
    </Link>)
}

const mapStateToProps = (state) => {
  return {
    language: language(state),
    user: state.settings.user,
    isHistoryBlocked: state.notifications.isHistoryBlocked,
    historyMessage: state.notifications.historyMessage,
    historyTitle: state.notifications.historyTitle,
    userSettings: state.settings.userSettings,
    isOpenNotification: state.notifications.isOpen,
    notifications: state.notifications.notifications,
    permission: state.settings.permission,
    IDPService: getIDPService(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
};

const componentWithTranslation = withRouter(withTranslation()(SideBar));
export default connect(mapStateToProps, mapDispatchToProps)(componentWithTranslation)

