import _ from 'lodash';
import { EDoctorPermissions } from '../../../reducers/settingReducer';
import { EDoctorRequestPrivacy, VENDORS_TYPES } from '../../helpers/groups';

export const TIME_OPTIONS = Object.freeze({
    all: 'all',
    today: 'today',
    since_yesterday: 'sinceYesterday',
    last_3_days: 'last3days',
    last_week: 'lastWeek',
    last_month: 'lastMonth',
    last_quarter: 'lastQuarter',
    rangeFilter: 'created-at-date-range-filter',
});

export const LIST_ORDER = Object.freeze({
    asc: 'asc',
    desc: 'desc',
});

export const BABY_AGE_OPTIONS = Object.freeze({
    ageAll: 'ageAll',
    babyUnderTwoWeeks: 'babyUnderTwoWeeks',
    babyUnder1month: 'babyUnder1month',
    babyUnder05month: 'babyUnder05month',
    babyUnder05year: 'babyUnder05year',
    babyUnder1year: 'babyUnder1year',
    child1_5year: 'child1_5year',
    childUnder25years: 'childUnder25years',
    over5year: 'over5year',
});

export const STATUS_FILTER_OPTIONS = Object.freeze({
    filterForAll: 'filterForAll',
    status1: 'status1',
    status2: 'status2',
    status3: 'status3',
    status33: 'status33',
    status4: 'status4',
});

export const DEFAULT_USER_STATUSES_ARR = ['status33','status3','status2', 'status1'];
export const FULL_STATUS_ARR = ['filterForAll', 'status1', 'status2', 'status3', 'status33', 'status4'];

export const filterByStatus = (value, array) => {
    return array.filter((elem) => value.some((element) => `status${elem.status}` === element))
};

export const sortByCustom = (type, flow, array) => {
    const arr = _.orderBy(array, ['isPrivate', type], [LIST_ORDER.desc, flow]);
    return arr;
};

export const makeStatusFilterItems = (permission, vendor) => {
  if (vendor === VENDORS_TYPES.yeledoctor) {
    return [
      { value: STATUS_FILTER_OPTIONS.filterForAll, status: false },
      { value: STATUS_FILTER_OPTIONS.status1, status: false },
      { value: STATUS_FILTER_OPTIONS.status2, status: false },
      { value: STATUS_FILTER_OPTIONS.status3, status: false },
      { value: STATUS_FILTER_OPTIONS.status33, status: false },
      { value: STATUS_FILTER_OPTIONS.status4, status: false },
    ];
  } else if (permission !== EDoctorPermissions.user) {
    return [
      { value: STATUS_FILTER_OPTIONS.filterForAll, status: false },
      { value: STATUS_FILTER_OPTIONS.status1, status: false },
      { value: STATUS_FILTER_OPTIONS.status2, status: false },
      { value: STATUS_FILTER_OPTIONS.status3, status: false },
      { value: STATUS_FILTER_OPTIONS.status33, status: false },
      { value: STATUS_FILTER_OPTIONS.status4, status: false },
    ]
  }
}

export const whichPermission = (privacy, patient) => {
  switch (privacy) {
    case EDoctorRequestPrivacy.private:
      return { ...patient, isPrivate: true };
    case EDoctorRequestPrivacy.other:
      return { ...patient, isOthers: true, isPrivate: false };
    case EDoctorRequestPrivacy.shared:
    default:
      return { ...patient, isShared: true, isPrivate: false };
  }
}