import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './assets/en.json'
import he from './assets/he.json'

const resources = {
  en: { translation: en }, 
  he: { translation: he }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'he',
    // fallbackLng: 'he',

    // keySeparator: true, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n